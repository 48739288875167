import React from 'react';
import PropTypes from 'prop-types';

import CardValidator from 'card-validator';

import { get } from 'lodash';
import { Form } from '@ant-design/compatible';
import { Col, Input, Row, Select } from 'antd';

const { Option } = Select;
const { Item: FormItem } = Form;

export const cards = {
  visa: "VI",
  mastercard: "MC",
  amex: "AE",
  "American Express": "AE",
};

export const AmexRate = (props) => {
  const { code, style } = props;

  let surcharge = '1.98%';

  // Mon Dec 16 2024 16:30:00 GMT+1100 (Australian Eastern Daylight Time)
  if (Date.now() > 1734327000) {
    surcharge = '2.09%';
  }

  return (
    <div style={style}>
      <small>
        {code === "stripe" ? (
          <>
            There is a <b>1.59% surcharge</b> for <b>Domestic American Express</b>
            <br />
            There is a <b>2.6% surcharge</b> for <b>Foreign American Express</b>
          </>
        ) : (
          <>
            There is a <b>{surcharge} surcharge</b> for <b>American Express</b>
          </>
        )}
      </small>
    </div>
  )
};

AmexRate.propTypes = {
  code: PropTypes.string,
  style: PropTypes.shape({}),
};

AmexRate.defaultProps = {
  code: null,
  style: { marginLeft: '25px' },
}

const CC_TYPES = {
  VI: {
    name: 'visa',
    logo: <img width="40px" title="Visa" alt="Visa Logo" src="/static/images/payments/visa.svg" />,
  },
  MC: {
    name: 'master-card',
    logo: <img width="40px" title="Master Card" alt="Master Card Logo" src="/static/images/payments/mastercard.svg" />,
  },
  AE: {
    name: 'american-express',
    logo: <img width="40px" title="American Express" alt="Amex Logo" src="/static/images/payments/amex.svg" />,
  }
};

export const validateCvv = (form, rule, value, callback) => {
  let maxLength = 3;
  if (form.getFieldValue('ccType') === 'AE') {
    maxLength = 4;
  }

  const valid = CardValidator.cvv(value, maxLength);
  if (!(valid.isPotentiallyValid && valid.isValid)) {
    callback('Invalid CVV');
    return;
  }
  callback();
}

export default class CreditCard extends React.Component {
  static propTypes = {
    form: PropTypes.shape({
      getFieldValue: PropTypes.func.isRequired,
      setFields: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    updateCardType: PropTypes.func.isRequired,
    is3ds: PropTypes.bool,
  }

  static defaultProps = {
    is3ds: false,
  }

  getCCImage = (type) => get(CC_TYPES[type], 'logo', null)

  static CC_TYPES = CC_TYPES

  handleCCTypeChange = (ccCardType) => {
    this.props.updateCardType(ccCardType);
  }

  validateType = (rule, value, callback) => {
    const number = this.props.form.getFieldValue('ccNumber');

    if (!number) {
      callback();
      return;
    }

    const valid = CardValidator.number(number);

    if (valid.card && CC_TYPES[value].name !== valid.card.type) {
      callback('Invalid Credit Card Type');
      return;
    }

    callback();
  }

  validateNumber = (rule, value, callback) => {
    const { form } = this.props;
    const valid = CardValidator.number(value);
    const ccType = form.getFieldValue('ccType');

    // set cc type validation status when cc numbers change
    if (ccType && valid.card) {
      if (!valid.isPotentiallyValid && CC_TYPES[ccType].name !== valid.card.type) {
        form.setFields({
          ccType: {
            value: ccType,
            errors: [Error('Invalid Credit Card Type')],
          }
        });
        callback();
        return;
      }

        form.setFields({
          ccType: {
            value: ccType,
            errors: null,
          }
        });

    }

    if (!valid.isValid) {
      callback('Invalid Credit Card Number');
      return;
    }
    callback();
  }

  validateDate = (form, rule, value, callback) => {
    if (form.getFieldValue('ccMonth') && form.getFieldValue('ccYear')) {
      const v = `${form.getFieldValue('ccMonth')}/${form.getFieldValue('ccYear')}`;
      const valid = CardValidator.expirationDate(v);
      if (!valid.isPotentiallyValid && !valid.isValid) {
        callback('Invalidate Date');
      } else {
        // remove month and year error messages
        form.setFields({
          ccMonth: {
            value: form.getFieldValue('ccMonth'),
            errors: null,
          },
          ccYear: {
            value: form.getFieldValue('ccYear'),
            errors: null,
          },
        });
        callback();
      }
    } else {
      callback();
    }
  }

  renderYears(form) {
    const { getFieldDecorator } = form;
    const years = [];
    let curYear = (new Date()).getFullYear();
    const endYear = curYear + 10;

    for (curYear; curYear <= endYear; curYear += 1) {
      years.push(curYear);
    }

    /* https://github.com/ant-design/ant-design/issues/25959
     * Select dropdown will auto close when nested inside radio,
     * this is a temporary fix, remove once antd fixes this.
     */
    return (
      <FormItem
        hasFeedback
        style={{ marginBottom: '0' }}
        onClick={(e) => { e.preventDefault() }}
      >
        {getFieldDecorator('ccYear', {
          rules: [
            { required: true, message: 'Required' },
            { validator: this.validateDate.bind(this, form) },
          ],
        })(
          <Select placeholder="Year" style={{width: '100%'}}>
            {years.map(y => <Option key={y} value={String(y)}>{y}</Option>)}
          </Select>
        )}
      </FormItem>
    );
  }

  render() {
    const { is3ds } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <FormItem
          hasFeedback
          style={{ marginBottom: '0' }}
        >
          {getFieldDecorator('ccOwner', {
            rules: [
              { required: true, message: 'Required' },
            ],
          })(<Input placeholder="Name On Card" />)}
        </FormItem>

        {is3ds && getFieldDecorator('ccType', {})(<div />)}

        {!is3ds && (
        <FormItem
          hasFeedback
          style={{ marginBottom: '0' }}
          extra={<AmexRate />}
        >
          {getFieldDecorator('ccType', {
            rules: [
              { required: true, message: 'Required' },
              { validator: this.validateType },
            ],
          })(
            <Select placeholder="Type" onChange={this.handleCCTypeChange}>
              <Option value="VI">{this.getCCImage('VI')} Visa</Option>
              <Option value="MC">{this.getCCImage('MC')} MasterCard</Option>
              <Option value="AE">{this.getCCImage('AE')} American Express</Option>
            </Select>
          )}
        </FormItem>
        )}

        <FormItem
          hasFeedback
          style={{ marginBottom: '0' }}
        >
          {!is3ds &&
            getFieldDecorator('ccNumber', {
              rules: [
                { required: true, message: 'Required' },
                { validator: this.validateNumber },
              ],
            })(<Input placeholder="Credit Card Number" />)}

          {is3ds && <div id="ccNumber" style={{height: '40px'}} />}
        </FormItem>

        <Row>
          <Col xs={12} md={10}>
            <FormItem
              style={{ marginBottom: '0', width: '100%' }}
              hasFeedback
              onClick={(e) => { e.preventDefault() }}
            >
              {getFieldDecorator('ccMonth', {
                rules: [
                  { required: true, message: 'Required' },
                  { validator: this.validateDate.bind(this, this.props.form) },
                ],
              })(
                <Select placeholder="Month" style={{width: '100%'}}>
                  <Option value="01">01 - January</Option>
                  <Option value="02">02 - February</Option>
                  <Option value="03">03 - March</Option>
                  <Option value="04">04 - April</Option>
                  <Option value="05">05 - May</Option>
                  <Option value="06">06 - June</Option>
                  <Option value="07">07 - July</Option>
                  <Option value="08">08 - August</Option>
                  <Option value="09">09 - September</Option>
                  <Option value="10">10 - October</Option>
                  <Option value="11">11 - November</Option>
                  <Option value="12">12 - December</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col xs={12} md={8}>
            {this.renderYears(this.props.form)}
          </Col>

          <Col xs={24} md={6}>
            <FormItem
              hasFeedback
              style={{ marginBottom: '0' }}
            >
              {!is3ds &&
                getFieldDecorator('ccCvv', {
                  rules: [
                    { required: true, message: 'Required' },
                    { validator: validateCvv.bind(this, this.props.form) },
                  ],
                })(<Input placeholder="CVV" />)}

              {is3ds && <div id="ccCvv" className="ant-form-item-control" style={{height: '40px'}} />}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}
