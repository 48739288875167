import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import Headroom from 'react-headroom';
import { getTocRoutes } from '~/components/cms/routes';

const { Header } = Layout;

export default class StickyHeader extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    style: PropTypes.shape({
    }),
    match: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    onUnpin: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    style: {},
    onUnpin: () => {},
  };

  render() {
    const { children, style, match, onUnpin } = this.props;
    const { pathname } = match.location;

    // disable Headroom on checkout & TOC page as it is covering,
    // content & form when it is has performed validateFieldsAndScroll
    const disablePaths = ['/checkout'].concat(getTocRoutes());
    const disableHeadroom = disablePaths.includes(pathname);

    return (
      <Headroom style={{zIndex: 10}} disable={disableHeadroom} onUnpin={onUnpin}>
        <Header id="header" style={style}>
          {children}
        </Header>
      </Headroom>
    );
  }
}
