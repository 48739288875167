import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import MediaQuery from 'react-responsive';
import { get, startsWith } from 'lodash';

import Cookies from 'universal-cookie';
import { AppleFilled, FacebookFilled, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import { checkIOS } from '~/helper';
import { saveDefaultSubrubPostcode } from '~/components/product/ShippingQuote';

import { LoginMutation, OauthLoginMutation } from './mutations';
import Register from './Register';
import ResetPassword from './ResetPassword';
import AppleSignin from './AppleSignin';
import GoogleSignin from './GoogleSignin';
import FacebookSignin from './FacebookSignin';
import MicrosoftSignin from './MicrosoftSignin';

import './style.css';

const FormItem = Form.Item;
const cookies = new Cookies();

export const getMaxAge = function getMaxAge(days) {
  const day = 24 * 60 * 60;
  return days * day;
};


export const buttonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '0px',
  color: 'white',
  height: '50px',
  overflow: 'hidden',
  padding: '2px 5px',
  textAlign: 'left',
  verticalAlign: 'top',
  width: '100%'
};

export const SignUpForm = (props) => {
  const { viewer } = props;

  return (
    <div style={{margin: '10px'}}>
      <h1>Sign Up</h1>
      <Register viewer={viewer} />
    </div>
  )
}

SignUpForm.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
}

export const handleSaveSuburbPostcode = (defaultAddress) => {
  const { city: suburb, region, postcode, lat: latitude, lng: longitude } = defaultAddress;

  if (process.env.COUNTRY === 'NZ') {
    saveDefaultSubrubPostcode({ suburb, city: region, postcode, latitude, longitude });
  } else {
    saveDefaultSubrubPostcode({ suburb, postcode, latitude, longitude });
  }
}

class Login extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    viewer: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      isActive: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
    }).isRequired,
    mobile: PropTypes.bool,
    bodyStyle: PropTypes.shape({}),
  }

  static defaultProps = {
    mobile: false,
    bodyStyle: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    const isInDrawer = startsWith(props.id, "account-drawer");

    // if page is one of account pages and user is not logged in, then it is necessary to show login form
    let defaultVisible = !props.viewer.email && props.router.isActive(props.match, { pathname: '/account'});
    if (isInDrawer) {
      defaultVisible = false;
    }

    this.state = {
      visible: defaultVisible,
      resetPassword: false,
      showSmSignUp: false,
      loading: false,
    };

    this.isIOS = checkIOS(true);
  }

  onOauthSuccess = (tokenId, provider) => {
    if (tokenId && provider) {
      this.oauthMutation({tokenId, provider});
    }
  }

  onOauthFailure = (errmsg) => {
    message.error(errmsg);
    this.setState({loading: false});
  }

  getHeading = () => {
    if (this.state.resetPassword) {
      return "Reset Password";
    }
    return "Log In";
  }


  getLoginForm = () => {
    const { id } = this.props;

    return (
      <Form ref={this.formRef} onFinish={this.handleSubmit}>
        <h1 style={{textAlign: 'center', margin: '0', fontWeight: '700'}}>{this.getHeading()}</h1>
        <div style={{textAlign: 'center', margin: '0 0 25px 0', fontWeight: '700', color: 'rgba(0, 0, 0, 0.85)'}}>
          Don&apos;t have an account?
          <Button
            style={{
              color: '#cb0000',
              border: 'none',
              boxShadow: 'none',
              fontWeight: '700',
              fontSize: 'medium',
              padding: '5px',
            }}
            onClick={()=> this.setState({showSmSignUp: true})}
            size="large"
          >
            Sign up
          </Button>
        </div>

        <FormItem
          name={["login", "email"]}
          rules={[
              { transform: (value) => (value || "").trim() },
              { required: true, message: 'Required' },
              { type: 'email', message: 'Invalid Email' },
          ]}
          style={{margin: '0 0 5px 0'}}
        >
          <Input autoComplete="username" placeholder="Email Address" size="large" />
        </FormItem>

        {!this.state.resetPassword && (
        <div>
          <FormItem
            name={["login", "password"]}
            rules={[
              { required: true, message: 'Required' },
            ]}
            style={{margin: '0'}}
          >
            <Input.Password autoComplete="current-password" type="password" placeholder="Password" size="large" />
          </FormItem>

          <FormItem
            style={{textAlign: 'right', margin: '0 0 3px 0'}}
          >
            <Button
              style={{color: '#cb0000', border: 'none', boxShadow: 'none', fontSize: '15px', fontWeight: '700'}}
              size="small"
              onClick={()=> this.setState({resetPassword: true})}
            >
              Forgot password?
            </Button>
          </FormItem>

          <FormItem>
            <Button
              style={{width: '100%'}}
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
              size="large"
            >
              Sign in
            </Button>
          </FormItem>
        </div>
        )}

        {!this.state.resetPassword && (
          <div>
            <p style={{textAlign: 'center', marginBottom: '18px', fontWeight: '700'}}>Or with</p>
            <div style={{margin: '10px 0px'}}>
              <FacebookSignin
                style={{width: '100%', backgroundColor: '#1877f2', color: 'white'}}
                onSuccess={this.onOauthSuccess}
                onFailure={this.onOauthFailure}
              >
                <FacebookFilled style={{fontSize: '27px', position: 'absolute', left: '14px'}} />
                Facebook
              </FacebookSignin>
            </div>
            <div style={{margin: '10px 0px'}}>
              <GoogleSignin
                id={`${id}-google-login-button`}
                onSuccess={this.onOauthSuccess}
                onFailure={this.onOauthFailure}
              />
            </div>
            <div style={{margin: '10px 0px'}}>
              <AppleSignin
                style={{width: '100%', backgroundColor: 'black', color: 'white'}}
                onSuccess={this.onOauthSuccess}
                onFailure={this.onOauthFailure}
              >
                <AppleFilled style={{fontSize: '27px', position: 'absolute', left: '14px'}} />
                Apple
              </AppleSignin>
            </div>
            <div style={{margin: '10px 0px'}}>
              <MicrosoftSignin
                style={{width: '100%'}}
                onSuccess={this.onOauthSuccess}
                onFailure={this.onOauthFailure}
              >
                <MicrosoftSignin.Logo
                  style={{position: 'absolute', left: '14px', top: '9px'}}
                />
                Hotmail / Microsoft
              </MicrosoftSignin>
            </div>
          </div>
        )}

        {this.state.resetPassword && (
          <div>
            <ResetPassword formRef={this.formRef} field={["login", "email"]} viewer={this.props.viewer} relay={this.props.relay} />
            <FormItem>
              <Button style={{width: '100%'}} onClick={()=> this.setState({resetPassword: false})} size="large">Back To Login</Button>
            </FormItem>
          </div>
        )}
      </Form>
    );
  }

  handleSubmit = (values) => {
    this.setState({ loading: true });
    const v = values.login;

    LoginMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: v },
      viewer: this.props.viewer,
      onCompleted: (resp, errors) => {
        this.setState({loading: false});
        if (!errors) {

          cookies.set('auth_token', resp.login.token, { path: '/', maxAge: getMaxAge(60) });

          const defaultAddress = get(resp, 'login.viewer.defaultAddress') || {};
          handleSaveSuburbPostcode(defaultAddress);

          if (!resp.login.viewer.securePassword) {
            const path = window.location.pathname + window.location.search;
            window.location.href = `/password-reset?redirect=${encodeURIComponent(path)}`;
          }
          else {
            window.location.reload();
          }
        }
      },
      onError: (errors) => {
        message.error(errors[0].message);
        this.setState({loading: false});
      }
    });
  }

  oauthMutation = (variables) => {
    OauthLoginMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: variables },
      viewer: this.props.viewer,
      onCompleted: (resp, errors) => {
        this.setState({loading: false});
        if (!errors) {

          cookies.set('auth_token', resp.oauthLogin.token, { path: '/', maxAge: getMaxAge(60) });

          const defaultAddress = get(resp, 'oauthLogin.viewer.defaultAddress') || {};
          handleSaveSuburbPostcode(defaultAddress);

          window.location.reload();
        }
      },
      onError: (errors) => {
        message.error(errors[0].message);
        this.setState({loading: false});
      }
    });
  }

  handleCancel = () => {
    if (this.isIOS) {
      document.body.style.position = "static";
    }
    this.setState({
      visible: false,
    });
  }

  showModal = () => {
    if (this.isIOS) {
      document.body.style.position = "fixed";
    }

    this.setState({
      visible: true,
    });
  }

  renderButton = () => {
    const { bodyStyle, mobile, viewer } = this.props;
    const showPriority = get(viewer, 'configs.priority', false);


    if (mobile) {
      return (
        <div key="btn" style={bodyStyle}>
          <div
            className="click-state"
            role="button"
            onClick={this.showModal}
            onKeyPress={this.showModal}
            tabIndex="0"
          >
            <UserOutlined style={{ display: 'inline-block', fontSize: '25px' }} />
            <br />
            <MediaQuery minWidth={301}>
              <span style={{ fontWeight: '600' }}>Login</span>
            </MediaQuery>
          </div>
        </div>
      );
    }

    return (
      <Button
        key="btn"
        className="account-button"
        onClick={this.showModal}
        style={{ ...buttonStyle, padding: showPriority ? '2px 5px' : '2px 20px' }}
      >
        <UserOutlined style={{ fontSize: '20px' }} />
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
          Login or Join
        </span>
      </Button>
    );
  }

  render() {
    const { viewer } = this.props;

    return !viewer.email && [
      this.renderButton(),
      <Modal
        key="modal"
        visible={this.state.visible}
        onCancel={this.handleCancel}
        footer={null}
        width="420px"
      >
        <Row>
          <Col xs={24} md={24} >
            <div style={{margin: '10px'}}>
              {!this.state.showSmSignUp && (
                <div>
                  {this.getLoginForm()}
                </div>
              )}

              {this.state.showSmSignUp && (
                <div>
                  <SignUpForm viewer={viewer} />

                  <Button style={{width: '100%'}} onClick={()=> this.setState({showSmSignUp: false})} size="large">Back To Login</Button>
                </div>
              )}

            </div>
          </Col>
        </Row>
      </Modal>,
    ];
  }
}
export default createFragmentContainer(Login, {
  viewer: graphql`
    fragment Login_viewer on Customer {
      email
      configs
    }
  `,
});
