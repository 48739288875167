import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { get } from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Select, Upload } from 'antd';
import { fileValidator } from '~/components/form';
import { PostCareerFormMutation } from './mutations';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;

class CareerPage extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      cmsPage: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleSubmit = (values) => {
    const uploadables = {};

    if (values.resume) {
      const fileList = values.resume.map(f => f.originFileObj);

      values.resume = fileList.map((f, i) => {
        const name = `resume${i}`;

        uploadables[name] = f;
        return name;
      });
    }

    PostCareerFormMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      uploadables,
      viewer: this.props.viewer,
      onCompleted: () => {
        message.success('Thank you very much, we have received your application.');
        this.formRef.current.resetFields();
      },
    });
  }

  render() {
    const { viewer } = this.props;
    const stores = get(viewer, 'stores.edges', []);
    const page = viewer.cmsPage;

    return (
      <div>
        <Helmet title={page.title} />
        <h1>{page.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: page.content }} />

        <h2>Application Form</h2>
        <Form ref={this.formRef} onFinish={this.handleSubmit}>

          <FormItem
            name="name"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Name" />
          </FormItem>

          <FormItem
            name="address"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Address" />
          </FormItem>

          <FormItem
            name="email"
            rules={[
              { required: true, message: 'Required' },
              { type: 'email', message: 'Invalid Email' },
            ]}
          >
            <Input placeholder="Email" />
          </FormItem>

          <FormItem
            name="telephone"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Telephone" />
          </FormItem>

          <FormItem
            name="location"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Select
              placeholder="Preferred location"
              style={{ width: 200 }}
            >
              {stores.map((edge) => {
                const store = edge.node;
                return <Option key={store.id} value={store.name}>{store.name}</Option>;
              })}
            </Select>
          </FormItem>

          <FormItem
            name="employment"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Select
              placeholder="Preferred type of work"
              style={{ width: 200 }}
            >
              <Option value="Casual">Casual</Option>
              <Option value="Full Time">Full time</Option>
              <Option value="Part Time">Part time</Option>
            </Select>
          </FormItem>

          <FormItem
            name="availability"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Select
              placeholder="Availability to start"
              style={{ width: 200 }}
            >
              <Option value="Immediately">Immediately</Option>
              <Option value="1 week">1 week</Option>
              <Option value="2 weeks">2 weeks</Option>
              <Option value="3 weeks">3 weeks</Option>
              <Option value="4 weeks">4 weeks</Option>
              <Option value="4+ weeks">4+ weeks</Option>
            </Select>
          </FormItem>

          <FormItem
            name="role"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Select
              placeholder="Applying for"
              style={{ width: 200 }}
            >
              <Option value="Sales">Sales</Option>
              <Option value="Storeman">Storeman</Option>
              <Option value="Graphic Designer">Graphic Designer</Option>
              <Option value="Procurement">Procurement</Option>
              <Option value="Accounts">Accounts</Option>
              <Option value="Marketing">Marketing</Option>
              <Option value="Tool Mechanic">Tool Mechanic</Option>
              <Option value="Legal">Legal</Option>
              <Option value="HR">HR</Option>
              <Option value="IT">IT</Option>
            </Select>
          </FormItem>

          <FormItem
            name="resume"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
            rules={[
              { required: true, message: 'Required' },
              { required: true, message: 'File cannot be over 2MB', validator: fileValidator.bind(this, 2)}
            ]}
          >
            <Upload
              customRequest={() => {}}
              accept=".pdf,.doc,.docx"
              listType="picture"
              beforeUpload={() => false}
            >
              <p>Files can be up to 2MB for file types .pdf .doc .docx</p>
              <Button>
                <UploadOutlined /> Resume
              </Button>
            </Upload>
          </FormItem>

          <FormItem
            name="comments"
            style={{marginTop: '20px'}}
          >
            <TextArea placeholder="Comments & Questions" rows={4} />
          </FormItem>

          <Button type="primary" htmlType="submit">
            Submit
          </Button>

        </Form>

      </div>
    );
  }
}

export default createFragmentContainer(CareerPage, {
  viewer: graphql`
    fragment CareerPage_viewer on Customer {
      stores(first: 9999) {
        edges {
          node {
            id
            name
          }
        }
      }
      cmsPage(urlSlug: $urlSlug) {
        title
        urlSlug
        content
      }
    }
  `,
});
