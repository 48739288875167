import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { Link } from 'found';
import { get } from 'lodash';

import CmsBanner from '../cms/CmsBanner';
import Helmet from '../page/Helmet';
import Breadcrumb from '../page/Breadcrumb';
import HeadingTitle from '../page/HeadingTitle';

import { renderBrand  } from './BrandList';
import { renderFaqs  } from '../contact/Contact';

const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';

const colStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '-1px 0 0 -1px',
  textAlign: 'center',
  border: '1px solid #e8e8e8',
}

const renderWideBrand = (brand, url, query={}) => {
  const [ w, h ] = ['100%', '100%'];

  return (
    <Link
      to={{
        pathname: url,
        query,
      }}
      className="nav-text black-href"
      style={{display: 'block', width: '100%', padding: '10px 10px 0px 10px'}}
    >
      <div className="click-state">
        <img width={w} height={h} alt={brand.name} className="image-link__image" src={brand.imageUrl || IMAGE_PLACEHOLDER} />
      </div>
      <div style={{paddingLeft: '2px', paddingRight: '2px', lineHeight: 1, margin: '10px 0'}}>{brand.name}</div>
    </Link>
  );
}

const render = (url, s, location) => {

  const [ xs, sm, md, lg ] = s.imageMode === 1 ? [24, 12, 12, 8] : [12, 8, 6, 4];
  const [ w, h ] = s.imageMode === 1 ? ['100%', '100%'] : [120, 120];

  return (
    <Col key={s.id} xs={xs} sm={sm} md={md} lg={lg} style={colStyles}>
      <Link
        to={{
          pathname: url,
          query: location.query,
        }}
        className="nav-text black-href"
        style={{display: 'block', width: '100%', padding: '10px 10px 0px 10px'}}
      >
        <div className="click-state">
          {s.imageUrl &&
            <img width={w} height={h} alt={s.name} className="image-link__image" src={s.imageUrl} />
            }
          {!s.imageUrl &&
            <img width={w} height={h} alt={s.name} className="image-link__image" src={IMAGE_PLACEHOLDER} />
            }
        </div>
        <div style={{paddingLeft: '2px', paddingRight: '2px', lineHeight: 1, margin: '10px 0'}}>
          {s.name} { s.docCount && `(${s.docCount})` }
        </div>
      </Link>
    </Col>
  );
};

export default class List extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        query: PropTypes.shape({}).isRequired,
        pathname: PropTypes.string,
      }).isRequired,
      params: PropTypes.shape({
        brandUrlSlug: PropTypes.string,
        categoryUrlSlug: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.combokitFilters = { filters: '{"tool_type":["Cordless"]}'};
  }

  getDescripiton = ({category, subcategory, brand, location, params}) => {
    if (location.query.filters && !subcategory) {
      /*
       * Remove description at
       * /category/by-brand/milwaukee?filters=%7B%22category%22%3A%20%5B%22Accessories%20-%20PTA%22%5D%7D
       */
      return null;
    }
    else if (get(brand, 'description') && !subcategory) {
      return <div dangerouslySetInnerHTML={{ __html: brand.description }} />;
    }
    else if (get(subcategory, 'description')) {
      return <div dangerouslySetInnerHTML={{ __html: subcategory.description }} />;
    }
    else if (category.description && !subcategory && !params.subcategoryUrlSlug && !params.subcategoryUrlSlug2) {
      return <div dangerouslySetInnerHTML={{ __html: category.description }} />;
    }
    return null;
  }

  getDescripiton2 = ({category, subcategory, brand, location, params}) => {
    if (location.query.filters && !subcategory) {
      /*
       * Remove description at
       * /category/by-brand/milwaukee?filters=%7B%22category%22%3A%20%5B%22Accessories%20-%20PTA%22%5D%7D
       */
      return null;
    }
    else if (get(brand, 'description2') && !subcategory) {
      return <div dangerouslySetInnerHTML={{ __html: brand.description2 }} style={{marginTop: '10px'}} />;
    }
    else if (get(subcategory, 'description2')) {
      return <div dangerouslySetInnerHTML={{ __html: subcategory.description2 }} />;
    }
    else if (category.description2 && !subcategory && !params.subcategoryUrlSlug && !params.subcategoryUrlSlug2) {
      return <div dangerouslySetInnerHTML={{ __html: category.description2 }} style={{marginTop: '10px'}} />;
    }
    return null;
  }

  render() {
    const { viewer, match: { location, params } } = this.props;
    const { brandUrlSlug } = params;
    const category = get(viewer, 'categories.edges[0].node', {});
    const subcategory = get(category, 'catalogues.subcategory', {});
    const brands = get(category, 'featuredBrandsNew.edges', []);
    const subcategories = get(category, 'catalogues.edges', []);
    const brand = get(category, 'catalogues.brand', {});
    const faqs = get(category , 'faqs.edges', []);

    return (
      <div>
        <Helmet category={category} subcategory={subcategory} brand={brand} location={location} show404={subcategories.length === 0} />
        <Breadcrumb category={category} subcategory={subcategory} location={location} brand={brand} />
        <HeadingTitle category={category} subcategory={subcategory} brand={brand} location={location} />

        <CmsBanner viewer={viewer} location={location} />

        {this.getDescripiton({category, subcategory, brand, location, params})}

        {subcategories.length === 0 &&
          'No Products Were Found'
        }

        {!subcategory && (
        <Row>
          {brands.map(({node: {brand: b, subcategory: s}}) => {
            const subcat = get(s, "name", null);
            let url = `/category/by-brand/${b.urlSlug}`;
            const query = {};

            if (subcat) {
              const formattedSubcategory = subcat.toLowerCase().replace(/\s+/g, '-');
              url += `/${formattedSubcategory}`;
            }

            const wide = b.imageUrl.includes("categories");

            const [ xs, sm, md, lg ] = wide ? [24, 12, 12, 8] : [12, 8, 6, 4];

            const styles = wide ?  colStyles : {
              margin: '-1px 0 0 -1px',
              height: '185px',
              textAlign: 'center',
              border: '1px solid #e8e8e8',
            };

            const renderFn = wide ? renderWideBrand : renderBrand;

            return (
              <Col key={b.id} xs={xs} sm={sm} md={md} lg={lg} style={styles}>
                {renderFn(b, url, query)}
              </Col>
            );
          })}
        </Row>
        )}

        <Row type="flex">
          {subcategories.map(({node: s}) => {
            // sorry for the hack here.
            // if this `subcategory`(you can tell it doesn't make sense) is a Category
            // then we don't need to modify the url, just display the link to the category
            if (s.__typename === 'Category')  {
              return render(`/category/${s.urlSlug}`, s, location);
            }

            let url = `/category/${category.urlSlug}`;

            if (category.urlSlug === 'by-brand') {
              url += `/${brandUrlSlug}`;
            }

            if (subcategory && subcategory.urlSlug) {
              url += `/${subcategory.urlSlug}`;
            }

            url += `/${s.urlSlug}`;

            if (s.link && category.urlSlug !== 'by-brand') {
              url = s.link;
            }

            return render(url, s, location);
          })}
        </Row>

        {this.getDescripiton2({category, subcategory, brand, location, params})}

        {renderFaqs(faqs)}

      </div>
    );
  }
}
