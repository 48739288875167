import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Button, Col, Divider, message, Popconfirm, Radio, Row } from 'antd';
import { get, isEqual } from 'lodash';

import { usePrevious } from '~/helper';
import { RemoveAddressMutation, UpdateDefaultAddressMutation } from '../account/mutations';
import AddressUpdatedSubscription from '../account/subscriptions/AddressUpdatedSubscription'
import { AddressForm } from '../account/AddressForm';
import { AddressInputs } from './AddressForm';

import './style.css';

const { Item: FormItem } = Form;
const { Group: RadioGroup } = Radio;

const radioStyle = {
  display: 'inline-flex',
  whiteSpace: 'initial',
  width: '100%',
};

const subscribeAddress = (props) => {
  const { viewer, relay: { environment } } = props;
  const id = get(viewer, 'id');
  const email = get(viewer, 'email');

  if (id && email) {
    AddressUpdatedSubscription(environment, { id });
  }
};

const getDefaultSelect = (type, viewer, addresses) => {
  const defaultAddress = type === "shipping" ? viewer.defaultShippingAddress : viewer.defaultBillingAddress;

  const { node: address } = addresses.find(({ node }) => node.id === defaultAddress?.id) || {};

  if (address) {
    return address.id;
  }

  return addresses.length === 0 ? 'newAddress' : null;
};

const AddressList = (props) => {
  const { form, viewer, relay, type, disabled } = props;
  const { getFieldDecorator } = form;

  const addresses = get(viewer, 'addresses.edges', []);
  const prevAddresses = usePrevious(addresses);

  const defaultValue = getDefaultSelect(type, viewer, addresses);
  const prevDefaultValue = usePrevious(defaultValue);

  const [state, setState] = useState({
    address: null,
    addressFormVisible: false,
  });

  const ensureDest = (addressId) => {
    if (type === "shipping") {
      const { node: address } = addresses.find(({ node }) => node.id === addressId) || {};

      if (addressId === "newAddress") {
        props.onChange({ dest: { street: '', suburb: '', city: '', postcode: '' } });
      } else if (address) {
        const { street, city: suburb, region: city, postcode } = address;
        props.onChange({ dest: { street, suburb, city, postcode } });
      }
    }
  };

  useEffect(() => {
    const { email } = viewer;

    if (email) {
      subscribeAddress(props);
    }
  }, []);

  useEffect(() => {
    if (!isEqual(prevAddresses, addresses)) {
      const shippingAddressId = form.getFieldValue("shippingAddressId");

      ensureDest(shippingAddressId);
    }
  }, [form, addresses]);

  useEffect(() => {
    if (prevDefaultValue && !isEqual(prevDefaultValue, defaultValue)) {
      ensureDest(defaultValue)
    }
  }, [prevDefaultValue, defaultValue]);

  const updateAddress = (address) => {
    setState((s) => ({ ...s, address, addressFormVisible: true }));
  };

  const updateSelectedAddress = (addressId) => {
    const fieldId = type === "billing" ? "billingAddressId" : "shippingAddressId";

    form.setFieldsValue({ [fieldId]: addressId });

    ensureDest(addressId);
  };

  // handle suburb/postcode selection
  const handleAddressSelect = (addressType, value, option) => {
    const selection = option.item;
    const { location: suburb, postcode, state: region } = selection;

    form.setFieldsValue({
      [`${addressType}[]city`]: suburb,
      [`${addressType}[]postcode`]: postcode,
      [`${addressType}[]region`]: region,
    });


    let dest = {};
    if (addressType === 'shipping') {
      dest = { suburb, city: region, postcode };
      props.onChange({ dest });
    }
  };

  const handleAddressTyping = (addressType, field, value) => {
    if (!value || value.includes('--')) {
      return;
    }

    const dest = {};
    if (addressType === 'shipping') {
      dest[field] = value;
      props.onChange({ dest });
    }
  };

  const deleteAddress = (address) => {
    RemoveAddressMutation.commit({
      environment: relay.environment,
      variables: { input: { id: address.id } },
      viewer,
    });
  };

  const updateDefaultAddress = (address) => {
    UpdateDefaultAddressMutation.commit({
      environment: relay.environment,
      variables: { input: { id: address.id } },
      viewer,
      onCompleted: (resp, errors) => {
        if (!errors) {
          const addressId = get(resp, "updateDefaultAddress.viewer.defaultAddress.id");
          updateSelectedAddress(addressId);
        }
      },
      onError: (errors) => {
        message.error(errors[0].message);
      }
    });
  };

  const onRadioChange = (e) => {
    const { value: addressId } = e.target;

    ensureDest(addressId);
  };

  const hideAddressFormModal = (resp) => {
    if (resp && Object.hasOwnProperty.call(resp, "updateAddress")) {
      const id = get(resp, "updateAddress.address.id");
      const shippingAddressId = form.getFieldValue("shippingAddressId");

      if (id === shippingAddressId) {
        const street = get(resp, "updateAddress.address.street");
        const postcode = get(resp, "updateAddress.address.postcode");
        const suburb = get(resp, "updateAddress.address.city");
        const city = get(resp, "updateAddress.address.region");

        props.onChange({ dest: { street, suburb, city, postcode } });
      }
    }

    setState((s) => ({ ...s, addressFormVisible: false }));
  };

  const renderSetDefault = (address) => {
    const isDefaultAddress = get(viewer, 'defaultAddress.id') === address.id;

    if (isDefaultAddress) {
      return null;
    }

    return (
      <Button type="link" onClick={() => { updateDefaultAddress(address) }}>
        Set as default
      </Button>
    );
  };

  const renderNewAddress = () => {
    return (
      <AddressInputs
        form={form}
        relay={relay}
        type={type}
        disabled={disabled}
        onChange={props.onChange}
        handleAddressSelect={handleAddressSelect}
        handleAddressTyping={handleAddressTyping}
      />
    )
  };

  const value = form.getFieldValue(`${type}AddressId`);

  return (
    <>
      <AddressForm
        viewer={viewer}
        address={state.address}
        relay={relay}
        addressFormVisible={state.addressFormVisible}
        onCancel={hideAddressFormModal}
      />

      <FormItem style={{ margin: '0px' }}>
        {getFieldDecorator(`${type}AddressId`, {
          rules: [
            { required: true, message: 'Required' },
          ],
          initialValue: defaultValue,
        })(
          <RadioGroup className="addresslist-radio" onChange={onRadioChange}>
            {addresses.map(({ node: a }) => (
              <div key={a.id}>
                <Radio key={a.id} style={radioStyle} value={a.id}>
                  <Row justify="space-between" style={{ display: 'inline-flex', verticalAlign: 'top', width: '89%' }}>
                    <Col xs={24} md={16}>
                      <div>
                        <b>{a.firstname} {a.lastname}</b>&nbsp;&nbsp;&nbsp;&nbsp;{a.telephone}<br />
                        {a.street} {a.city} {a.region} {a.postcode} {a.country.name}<br />
                        {a.company} {a.fax}
                      </div>
                    </Col>
                    <Col xs={24} md={8}>
                      <div>
                        <Button type="link" onClick={() => { updateAddress(a); }}>Edit</Button>
                        <Popconfirm
                          title="Delete this address?"
                          onConfirm={() => { deleteAddress(a); }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link"><DeleteOutlined /></Button>
                        </Popconfirm>

                        {renderSetDefault(a)}
                      </div>
                    </Col>
                  </Row>
                </Radio>
                <Divider style={{ margin: '5px' }} />
              </div>
            ))}
            <Radio style={radioStyle} value="newAddress">
              <b>New Address</b>
            </Radio>
          </RadioGroup>
        )}
      </FormItem>
      {(value === 'newAddress' || addresses.length === 0) ? renderNewAddress() : null}
    </>
  )
};

AddressList.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  viewer: PropTypes.shape({
    email: PropTypes.string,
    defaultBillingAddress: PropTypes.shape({}),
    defaultShippingAddress: PropTypes.shape({}),
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
  type: PropTypes.oneOf(['billing', 'shipping']).isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

AddressList.defaultProps = {
  onChange: () => { },
};

export default createFragmentContainer(AddressList, {
  viewer: graphql`
    fragment AddressList_viewer on Customer {
      id
      email
      addresses(first: 999) @connection(key: "AddressBook_addresses") {
        edges {
          node {
            id
            firstname
            lastname
            street
            city
            postcode
            region
            telephone
            company
            fax
            country {
              name
            }
          }
        }
      }
      defaultBillingAddress {
        id
        firstname
        lastname
        street
        city
        postcode
        region
        telephone
        company
        fax
        country {
          name
        }
      }
      defaultShippingAddress {
        id
        firstname
        lastname
        street
        city
        postcode
        region
        telephone
        company
        fax
        country {
          name
        }
      }
      defaultAddress {
        id
      }
    }
  `,
});
