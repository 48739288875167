import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import './style.css';

// Check if the character is not an alphabet letter
export const isInAlphabet = (char) => {
  return /^[A-Za-z]$/.test(char);
}

// Map the list of data into a map where each key is a letter (A-Z or #)
export const getLetterMap = (brands) => {
  const letterMap = new Map();

  brands.forEach(({ node }) => {
    const brandName = node.name;
    let firstLetter = brandName[0].toUpperCase();

    if (!isInAlphabet(firstLetter)) firstLetter = "#";

    if (!letterMap.get(firstLetter)) letterMap.set(firstLetter, brandName);
  });

  return letterMap;
}

const AlphabetScroll = (props) => {
  const { brandLetterMap } = props;
  const keyArr = Array.from(brandLetterMap.keys());
  const href = window.location.hash.substring(1); // Get the href without the '#'
  const [activeKey, setActiveKey] = useState(brandLetterMap.has(href));

  // Check if an element's rectangle is in the current viewport
  const isInViewport = (elemRect) => {
    if (!elemRect) return false;

    return elemRect.bottom > 0 && elemRect.top <= (window.innerHeight || document.documentElement.clientHeight);
  }

  // Controls the position of the scrolling alphabet bar
  const handleStickyScroll = () => {
    const scrollElem = document.querySelector('.alphabet-selector');
    const scrollElemRect = scrollElem.getBoundingClientRect();
    const header = document.getElementById('header')?.getBoundingClientRect();
    const footer = document.getElementById('footer')?.getBoundingClientRect();
    const footerTop = footer ? footer.top : 0;
    const headerBottom = header ? header.bottom : 0;

    // When screen is large enough calculate scrollbar offset to
    // center in viewport otherwise default to 15px
    let scrollOffset = 15;
    if (window.innerHeight - scrollElemRect.height > 0) {
      scrollOffset = (window.innerHeight - scrollElemRect.height)/2;
    }

    // Position the scrollbar relative to the footer, then header
    // Otherwise position the scrollbar in the center of viewport
    if (isInViewport(footer)) {
      scrollElem.style.position = 'absolute';
      scrollElem.style.top = "";
      scrollElem.style.bottom = `${scrollOffset}px`;
    } else if (isInViewport(header)) {
      if (!(footerTop - scrollOffset*2 <= window.innerHeight)) {
        scrollElem.style.position = 'fixed';
        scrollElem.style.bottom = '';
        scrollElem.style.top = `${headerBottom + scrollOffset}px`;
      }
    } else {
      scrollElem.style.position = 'fixed';
      scrollElem.style.top = `${scrollOffset}px`;
    }
  }

  const handleClick = (letter) => {
    const targetElement = document.getElementById(letter);
    const targetElementTop = targetElement.getBoundingClientRect().top;

    // When scrolling up the page the header comes into view so we need an offset
    let headerOffset = 0;
    if (targetElementTop < 0) {
      const header = document.getElementById('header');
      headerOffset = header ? header.offsetHeight : headerOffset;
    }

    // Scrolling to element position based on elem relative pos + curr pos - header
    const offsetFromTop = targetElementTop + window.scrollY - headerOffset;
    window.scrollTo({
      top: offsetFromTop,
      behavior: 'smooth'
    });

    window.history.pushState(null, null, `#${letter}`);
    setActiveKey(letter);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleStickyScroll);

    handleStickyScroll();

    return () => {
      window.removeEventListener("scroll", handleStickyScroll);
    }
  }, [])

  // Handle element highlighting when the active key or href changes
  useEffect(() => {
    // Updates the active key when navigating back or forward in browser history
    // Otherwise highlight the currently selected elements
    if (activeKey !== href) {
      setActiveKey(href);
    } else {
      // Removing any previously highlighted elements
      const prevElementsInLetter = document.querySelectorAll(".brand-highlight");

      if (prevElementsInLetter.length > 0) {
        prevElementsInLetter.forEach((element) => {
          element.className = element.className.replace('brand-highlight', '')
        })
      }

      // Set the new highlighted elements if active key is within map
      if (brandLetterMap.has(activeKey)) {
        const brandClassName = activeKey === "#" ? "digit" : activeKey;
        const brandsInLetter = document.querySelectorAll(`.${brandClassName}`);

        // Highlighting currently active elements
        brandsInLetter.forEach((brand) => {
          brand.className = `${brand.className} brand-highlight`;
        })
      }
    }
  }, [href, activeKey])

  return (
    <div
      className="alphabet-selector"
    >
      <Col style={{ borderRadius: "15px" }} >
        {keyArr.map((letter) => {
          return (
            <Row key={letter}>
              <button
                className={`alphabet-selector-button ${activeKey === letter ? "active" : ""}`}
                onClick={() => handleClick(letter)}
              >
                <span className="alphabet-selector-text">{letter}</span>
              </button>
            </Row>
          );
        })}
      </Col>
    </div>
  );
}

AlphabetScroll.propTypes = {
  brandLetterMap: PropTypes.objectOf(PropTypes.string).isRequired
};

export default AlphabetScroll;
