/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Jss_viewer$ref = any;
export type routes_Jss_QueryVariables = {||};
export type routes_Jss_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Jss_viewer$ref
  |}
|};
export type routes_Jss_Query = {|
  variables: routes_Jss_QueryVariables,
  response: routes_Jss_QueryResponse,
|};
*/


/*
query routes_Jss_Query {
  viewer {
    ...Jss_viewer
    id
  }
}

fragment Jss_viewer on Customer {
  id
  stores(first: 9999) {
    edges {
      node {
        id
        name
      }
    }
  }
  categories(first: 1, urlSlug: "by-brand") {
    edges {
      node {
        id
        brands(first: 999) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_Jss_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Jss_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_Jss_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 9999
              }
            ],
            "concreteType": "StoreConnection",
            "kind": "LinkedField",
            "name": "stores",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v1/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "stores(first:9999)"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Literal",
                "name": "urlSlug",
                "value": "by-brand"
              }
            ],
            "concreteType": "CategoryConnection",
            "kind": "LinkedField",
            "name": "categories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CategoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Category",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 999
                          }
                        ],
                        "concreteType": "BrandConnection",
                        "kind": "LinkedField",
                        "name": "brands",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BrandEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Brand",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v1/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "brands(first:999)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "categories(first:1,urlSlug:\"by-brand\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "261c635e77217ac092e115365d52a51b",
    "id": null,
    "metadata": {},
    "name": "routes_Jss_Query",
    "operationKind": "query",
    "text": "query routes_Jss_Query {\n  viewer {\n    ...Jss_viewer\n    id\n  }\n}\n\nfragment Jss_viewer on Customer {\n  id\n  stores(first: 9999) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  categories(first: 1, urlSlug: \"by-brand\") {\n    edges {\n      node {\n        id\n        brands(first: 999) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24415a61ca56a7e8657611536d1dfcb3';

module.exports = node;
