/* global google */
import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { get } from 'lodash';
import { importScript } from '~/helper';
import { GetTyroSessionMutation } from '../mutations';
import Tyro from './Tyro';

const { Threeds, getTransDetails } = Tyro;

const BUTTON_ID = 'googlepay-container';

let environment = 'TEST';
let gatewayMerchantId = null;
let gateway = null;
let tillGatewayMerchantId = "ME-3f3f-2dd0-70eb-4191-6c03-0e4b";
const tillGateway = "ixopay";
let tyroGatewayMerchantId = "SYDTOOLS"
const tyroGateway = "mpgs";
let MERCHANT_ID = 'BCR2DN6TT6O27LAT';

if (process.env.NODE_ENV === 'production') {
  environment = 'PRODUCTION';
  tillGatewayMerchantId = "ME-db43-0bee-c89c-cd01-f077-0ee3";
  tyroGatewayMerchantId = "TYRO_94617";
}

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0
};

const allowedCardNetworks = ["AMEX", "MASTERCARD", "VISA"];

function getTokenizationSpecification() {
  return {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway,
      gatewayMerchantId,
    }
  }
}

// CRYPTOGRAM_3DS = DPAN, cannot go through 3ds authentication.
// PAN_ONLY = FPAN, recommended to run 3ds authentication for risk checks.
function baseCardPaymentMethod(paymentMethods) {
  let allowedCardAuthMethods = ["CRYPTOGRAM_3DS"];

  const isTyro = paymentMethods.find(({node}) => node.code === 'tyro_googlepay');

  if (isTyro) {
    allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];
  }

  return {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks,
      assuranceDetailsRequired: true,
    }
  }
}

function getCardPaymentMethod(paymentMethods) {
  const tokenizationSpecification = getTokenizationSpecification();
  return Object.assign(
    {},
    baseCardPaymentMethod(paymentMethods),
    {
      tokenizationSpecification
    }
  );
}

let paymentsClientCache = null;

function getGoogleIsReadyToPayRequest(paymentMethods) {
  return Object.assign(
    {},
    baseRequest,
    {
      allowedPaymentMethods: [baseCardPaymentMethod(paymentMethods)]
    }
  );
}

function processTyroPayment(paymentData, cart, form, submit, relay) {
  const paymentMethod = 'tyro_googlepay';
  const id = get(cart, 'id', '');
  const method = get(cart, 'paymentMethods.edges', []).find(({node}) => node.code === paymentMethod);
  const token = get(paymentData, 'paymentMethodData.tokenizationData.token');

  GetTyroSessionMutation.commit({
    environment: relay.environment,
    variables: { input: { googleToken: token } },
    onCompleted: (resp) => {
      const { sessionId } = resp.getTyroSession;
      const version = get(method, 'node.extra.version');

      // https://developers.google.com/pay/api/web/reference/response-objects#assurance-details-specifications
      if (sessionId && version) {
        const cardHolderAuthenticated = get(paymentData, 'paymentMethodData.info.assuranceDetails.cardHolderAuthenticated', false);
        const accountVerified = get(paymentData, 'paymentMethodData.info.assuranceDetails.accountVerified', false);

        const formValues = {
          ccType: paymentData.paymentMethodData.info.cardNetwork.toLowerCase(),
          googlepay: paymentData,
          paymentMethod,
          tyroData: {
            sessionId,
          },
        };

        if (cardHolderAuthenticated && accountVerified) {
          form.setFieldsValue(formValues, () => {
            submit();
          });
        } else {
          const { transactionId } = getTransDetails(id);

          formValues.tyroData.transactionId = transactionId;

          form.setFieldsValue(formValues, () => {
            submit();
          });
        }
      }
    },
  });
}

function processPayment(paymentData, form, submit) {
  form.setFieldsValue({
    ccType: paymentData.paymentMethodData.info.cardNetwork.toLowerCase(),
    googlepay: paymentData,
    paymentMethod: 'till_googlepay',
  });

  submit();
}

function getGoogleTransactionInfo(cart) {
  return {
    countryCode: process.env.COUNTRY,
    currencyCode: process.env.CURRENCY,
    totalPriceStatus: 'ESTIMATED',
    // set to cart total
    totalPrice: cart.grandTotal.toString()
  };
}

function getGooglePaymentDataRequest(cart) {
  const paymentMethods = get(cart, 'paymentMethods.edges', []);
  const cardPaymentMethod = getCardPaymentMethod(paymentMethods);
  const paymentDataRequest = Object.assign({
    // callbackIntents: ["PAYMENT_AUTHORIZATION"],
    allowedPaymentMethods: [cardPaymentMethod],
    transactionInfo: getGoogleTransactionInfo(cart),
    merchantInfo: {
      merchantId: MERCHANT_ID,
      merchantName: 'Sydney Tools'
    },
  }, baseRequest);

  return paymentDataRequest;
}

function getGooglePaymentsClient() {
  if ( paymentsClientCache === null ) {
    paymentsClientCache = new google.payments.api.PaymentsClient({
      environment
    });
  }
  return paymentsClientCache;
}

function onGooglePaymentButtonClicked(cart, form, submit, relay) {
  form.validateFieldsAndScroll((error) => {
    if (!error) {
      const paymentDataRequest = getGooglePaymentDataRequest(cart);
      const paymentsClient = getGooglePaymentsClient();

      paymentsClient.loadPaymentData(paymentDataRequest)
      .then(paymentData => {
        const isTyro = cart.paymentMethods.edges.find(({node}) => node.code === 'tyro_googlepay');

        if (isTyro) {
          processTyroPayment(paymentData, cart, form, submit, relay);
        } else {
          processPayment(paymentData, form, submit);
        }
      })
      .catch(err => {
        console.error(err);
      });
    } else {
      message.error("Please check your details in the form");
    }
  });
}

function addGooglePayButton(cart, form, submit, relay) {
  const paymentsClient = getGooglePaymentsClient();
  const button = paymentsClient.createButton({
    buttonType: 'plain',
    buttonSizeMode: 'fill',
    onClick: onGooglePaymentButtonClicked.bind(this, cart, form, submit, relay)
  });
  document.getElementById(BUTTON_ID).appendChild(button);
}

const LoadGooglePay = (paymentMethods) => {
  const paymentsClient = getGooglePaymentsClient();

  return paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest(paymentMethods))
  .then(response => Promise.resolve(response))
  .catch(err => Promise.reject(err));
}

export const onGooglePayLoaded = (cart, form, submit, relay) => {
  const paymentMethods = get(cart, 'paymentMethods.edges', []);
  LoadGooglePay(paymentMethods).then(response => {
    if (response.result) {
      addGooglePayButton(cart, form, submit, relay);
    }
  });
}

export default class GooglePay extends React.Component {

  // Without PAN_ONLY, Google Pay will not be available to Desktop.
  // So to even show the option of paying with Google Pay, it is necessary
  // to init Google Pay, then get a result bool to indicate if the option should be presented
  static canShow = (paymentMethods) => {
    const hasGooglePay = !!paymentMethods.find(({node}) => node.title === "Google Pay");

    return new Promise((resolve, reject) => {
      // if google pay is disabled, quick return
      if (!hasGooglePay) {
        resolve(false);
      } else {
        const load = () => {
          LoadGooglePay(paymentMethods)
            .then(resp => resolve(resp.result))
            .catch(err => reject(err))
        };

        if (!window.google || !window.google.payments) {
          importScript("https://pay.google.com/gp/p/js/pay.js", () => {
            load();
          });
        }
        else {
          load();
        }
      }
    });
  }

  static importScript(cart, form, submit, relay) {
    if (!window.google || !window.google.payments) {
      importScript("https://pay.google.com/gp/p/js/pay.js", () => {
        onGooglePayLoaded(cart, form, submit, relay);
      });
    }
    else {
      onGooglePayLoaded(cart, form, submit, relay);
    }
  }

  static propTypes = {
    cart: PropTypes.shape({
      paymentMethods: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    form: PropTypes.shape({
      getFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
      validateFieldsAndScroll: PropTypes.func.isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    submit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const googlepay = get(props, 'cart.paymentMethods.edges', []).find(({node}) =>
      node.code === 'till_googlepay' || node.code === 'tyro_googlepay'
    );

    this.googlepay = googlepay;

    if (googlepay) {
      // defined at the very top
      environment = googlepay.node.env;
      MERCHANT_ID = googlepay.node.extra.merchant_id;
      this.setGateway(googlepay);

      if (environment && MERCHANT_ID) {
        GooglePay.importScript(props.cart, props.form, props.submit, props.relay);
      }
    }
  }

  setGateway = (googlepay) => {
    const { code } = googlepay.node;

    if (code === "tyro_googlepay") {
      gatewayMerchantId = tyroGatewayMerchantId;
      gateway = tyroGateway;
    } else {
      gatewayMerchantId = tillGatewayMerchantId;
      gateway = tillGateway;
    }
  }

  render() {
    const { cart: { paymentMethods }, form } = this.props;
    const { getFieldDecorator } = form;

    if (GooglePay.canShow(paymentMethods.edges)) {
      return (
        <div
          id={BUTTON_ID}
          style={{
            width: '100%',
          }}
        >
          {getFieldDecorator('googlepay')(<div />)}
          {getFieldDecorator('ccType')(<div />)}
          {get(this.googlepay, 'node.code') === 'tyro_googlepay' && (<Threeds form={form} />)}
        </div>
      )
    }

    return null;
  }
}
