import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { get } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, InputNumber, Radio, Row, Tabs } from 'antd';
import Helmet from '~/components/page/Helmet';
import {
  JSONLD,
  Generic,
} from 'react-structured-data';
import { Link } from 'found';
import URL from 'url-parse';
import ReactPixel from 'react-facebook-pixel';

import { imageStyle } from '~/components/category/BrandList';
import MediaQuery from 'react-responsive';
import GA4 from '~/ga4';

import WishlistBtn from '~/components/account/wishlist/WishlistBtn';
import { AddProductToCartMutation } from '../cart/mutations';
import Price from './Price';
import SelectConfigProduct from './SelectConfigProduct'
import ShippingQuote from './ShippingQuote';
import Media from './Media';
import TierPrice from './TierPrice';
import Freebie from './Freebie';
import { ReviewList, ReviewForm } from '../review';
import { ProductId } from './ProductId';
import { renderDiscount, renderLoyaltyPoints } from './ProductLabel';
import BundleContent from './BundleContent';
import Popover from './Popover';
import List from './List';
import PriceMatch from './PriceMatch';
import CmsBanner from '../cms/CmsBanner';
import ShipOn from './ShipOn';
import ProductStock from './ProductStock';
import Related from './Related';
import YouTubeVideo from './YouTubeVideo';
import { isFreeShipping } from './helper';
import { DisplayHummLogo } from '../checkout/payment/Humm';
import { DisplayZipLogo } from '../checkout/payment/Zip';
import { openCartToast } from '../cart/helper';
import { AfterPayWidget } from '../checkout/payment/AfterPay';
import GetByDate from '../priority/GetByDate';

const { TabPane } = Tabs;
const { Group: RadioGroup } = Radio;
const { Panel } = Collapse;
const urlPrefix = "/product/";

const buttonStyle = {fontWeight: 'bold', width: '100%', fontSize: '20px'};

const { ShowPopover } = Popover;

class ProductView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      location: PropTypes.shape({
      }).isRequired,
    }).isRequired,
    router: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      freeShippingAmount: PropTypes.number,
      salesNumber: PropTypes.string.isRequired,
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      bonusProducts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    router: null,
  }

  constructor(props) {
    super(props);

    const product = get(props.viewer, 'products.edges[0].node', {});

    this.state = {
      bonusOptionId: this.getDefaultBonus(),
      quantity: 1.0,
      configParentProductId: (product.type === "configurable" ? product.id : null),
      currentConfigProduct: get(product, 'configurables.edges[0].node', null),
      isConfigProductValid: null,
      getByDate: null,
    };

    ReactPixel.track(
      'ViewContent',
      {
        contents: [
          { id: product.sku, quantity: 1 }
        ],
        content_name: product.name,
        content_type: 'product',
        value: product.price || null,
        currency: process.env.CURRENCY,
      }
    );

    GA4.viewItem(product);
  }

  getDefaultBonus = () => {
    const { viewer } = this.props;
    const product = get(viewer, 'products.edges[0].node', {});
    const bonusProducts = get(product, 'bonusProducts.edges', []);
    const optionalBonus = bonusProducts.filter(edge => edge.node.optional === true);
    return optionalBonus.length > 0 ? optionalBonus[0].node.productId : null;
  }

  getProduct = (viewer) => {
    const product = get(viewer, 'products.edges[0].node', {});

    if (product.type === 'configurable' && this.state.currentConfigProduct != null) {
      return this.state.currentConfigProduct;
    }
    return product;
  }

  getJsonLdSchema = (product) => {
    const productUrl = this.getUrl(product);
    const image = get(product, 'mainImage.thumbnail', '') || get(product, 'images[0].thumbnail', '');
    const attributes = get(product, 'attributes') || {};

    const schema = {
      name: product.name,
      model: product.model,
      image,
      description: product.metaDescription,
      brand: {
        "@type": "Brand",
        name: get(product, 'brand.name', ''),
      },
      sku: product.sku,
      offers: {
        "@type": "Offer",
        price: product.price,
        priceCurrency: process.env.CURRENCY,
        availability: "http://schema.org/InStock",
        itemCondition: "http://schema.org/NewCondition",
        url: productUrl.toString(),
      }
    };

    if (attributes?.size) {
      schema.size = Array.isArray(attributes.size) ? attributes.size[0] : attributes.size;
    }

    if (attributes?.color) {
      schema.color = Array.isArray(attributes.color) ? attributes.color[0] : attributes.color;
    }

    return schema;
  }

  getUrl = (product) => new URL(`product/${ product.urlSlug }`, window.location.origin);

  shouldDisableButton = () => {
    const parentProduct = get(this.props.viewer, 'products.edges[0].node', {});
    if (parentProduct.type === "configurable") {
      if (this.state.isConfigProductValid || this.state.isConfigProductValid === null ) {
        return false;
      }
      return true;
    }
    return false;
  };

  updateConfigProduct = (product, isConfigProductValid) => {
    if (product !== null) {
      this.setState({ currentConfigProduct: product });
    }
    this.setState({ isConfigProductValid });
  }

  initProduct = () => {
    this.setState({
      bonusOptionId: this.getDefaultBonus(),
    });
  }

  handleAdd = (product) => {
    const input = {
        id: product.id,
        quantity: this.state.quantity,
    };

    if (this.state.configParentProductId) {
      input.parentProductId = this.state.configParentProductId;
    }

    const { bonusOptionId } = this.state;
    if (bonusOptionId) {
      input.optionalBonusId = bonusOptionId;
    }

    const variables = { input };

    AddProductToCartMutation.commit({
      environment: this.props.relay.environment,
      variables,
      viewer: this.props.viewer,
      parent: product,
      onCompleted: () => {
        ReactPixel.track(
          'AddToCart',
          {
            contents: [
              { id: product.sku, quantity: this.state.quantity }
            ],
            content_name: product.name,
            content_type: 'product',
            value: product.price || null,
            currency: process.env.CURRENCY,
          }
        );
        GA4.addToCart(product, this.state.quantity);
        openCartToast(product, this.props);
      },
    });
  };

  handleGetByDate = (date) => {
    this.setState({ getByDate: date });
  }

  bonusOptionChange = (e) => {
    this.setState({ bonusOptionId: e.target.value });
  }

  renderDescription = (product, finalProduct) => {
    const videos = get(product, 'videos', []) || [];
    let description = '';

    if (product.description) {
      description += `
        <h2>${product.name}</h2>
        ${(product).description}
        <br/>
        ${(finalProduct || product).specification || ''}`;
    }

    // bundle does not have warranty on itself
    if (product.type !== 'bundle' && product.warranty) {
      description += `<div>Warranty: <ul><li>${product.warranty}</li></ul></div>`;
    }

    if (product.type === 'bundle') {
      product.bundles.edges.forEach((edge) => {
        const child = edge.node;
        const image = get(child, 'images[0]', {});
        if (Object.prototype.hasOwnProperty.call(image, 'url')) {
          description += `<a href=/product/${child.urlSlug} target="_blank" rel="noopener noreferrer"><img style="vertical-align: middle;" width="100" src="${image.url}"></a>`;
        }
        description += `
          <h2 style="display: inline-block">
          <a href=/product/${child.urlSlug} target="_blank">${child.quantity} x ${child.name}</a>
          </h2>
          ${child.description || ''}
          <br/>
          ${child.specification || ''}
          <br/>
          ${child.warranty || ''}
          <br/>
          `;
      });
    }

    const commonPromobanners = get(product, 'commonPromoBanners', []) || [];

    return (
      <>
        {commonPromobanners.length > 0 && (
          <Row style={{ marginBottom: '10px' }}>
            {commonPromobanners.map(([srcBig, srcSmall], index) => {
              if (typeof srcBig === 'string' && typeof srcSmall === 'string') {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Col key={index}>
                    <MediaQuery minWidth={769}>
                      <img className="img-fluid" alt="Big Promotional Banner" style={{marginBottom: '5px'}} src={srcBig} />
                    </MediaQuery>
                    <MediaQuery maxWidth={768}>
                      <img className="img-fluid" alt="Small Promotional Banner" style={{marginBottom: '5px'}} src={srcSmall} />
                    </MediaQuery>
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        )}
        {!!videos.length && (
          <YouTubeVideo link={videos[0]} />
        )}
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </>
    );
  }

  renderAddCartButton = (product) => {
    if (product.sellable) {

      const onClick = () => {this.handleAdd(product)};

      const btn = (
        <div style={{width: '88%', float: 'left'}}>
          <div style={{width: '80px', float: 'left'}}>
            <InputNumber
              className="input-quantity"
              disabled={this.shouldDisableButton()}
              min={1}
              type="number"
              max={999}
              defaultValue={this.state.quantity}
              size="large"
              style={{width: '100%', textAlign: 'center'}}
              onChange={quantity => {
                if (quantity) {
                  this.setState({ quantity });
                }
              }}
            />
          </div>
          <div style={{width: 'auto', overflow: 'hidden'}}>
            <Button
              type="primary"
              size="large"
              disabled={this.shouldDisableButton()}
              onClick={onClick}
              style={buttonStyle}
            >
              <PlusOutlined /> Add To Cart
            </Button>
          </div>
        </div>
      );

      return <ShowPopover product={product} btn={btn} />;
    } else if (product.callForPrice) {
      return (
        <a title="Call Now" href={`tel:${this.props.viewer.salesNumber}`}>
          <Button type="primary" size="large" style={{buttonStyle, width: '88%'}}>Call For Price</Button>
        </a>
      );
    } else if (product.inStoreOnly) {
      return (
        <Button type="primary" size="large" style={{buttonStyle, width: '88%'}}>In Store Only</Button>
      );
    }
    return <div>This product is currently unavailable</div>;
  }

  renderPaymentLogos = (price) => {
    if (process.env.COUNTRY === 'NZ') {
      return (
        <div style={{textAlign: 'center'}}>
          <DisplayZipLogo price={price} />
        </div>
      );
    }

    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <DisplayHummLogo />

        <span style={{ marginLeft: '10px', marginRight: '10px'}}>|</span>

        <DisplayZipLogo price={price} />
      </div>
    )
  }

  renderPanels = (viewer, product, type="tabs") => {
    const files = get(product.files, 'edges', []);
    const videos = get(product, 'videos', []) || [];

    const finalProduct = this.getProduct(viewer);

    const panels = [
      {
        key: "Description",
        content: this.renderDescription(product, finalProduct)
      },
      {
        key: "Downloads",
        content: files.length && files.map(edge => (
          <div key={edge.node.id}>
            <a href={edge.node.url} target="_blank" rel="noopener noreferrer">{edge.node.name}</a>
          </div>
        ))
      },
      {
        key: "Videos",
        content: videos.length && videos.map(i => <YouTubeVideo key={i} link={i} />)
      },
      {
        key: "Kit Content",
        content: product.type === 'bundle' && product.ac === null && <BundleContent viewer={viewer} product={product} />
      },
      {
        key: "Reviews",
        content: (
          <div>
            <h3 style={{float: 'left'}}>Leave a review</h3>
            <div style={{float: 'right'}}>
              <div id="ratingbadge" />
            </div>
            <div className="clearfix" />
            {viewer.email && <ReviewForm viewer={viewer} product={finalProduct} />}
            {!viewer.email && 'Please Login to leave a review'}
            <Divider />
            <ReviewList product={finalProduct} viewer={viewer} />
          </div>
        )
      },
    ].filter(p => !!p.content)

    if (type === 'collapse') {
      return (
        <Collapse
          accordion
          defaultActiveKey="Description"
          style={{backgroundColor: 'white', border: 'none'}}
        >
          {panels.map(p => (
            <Panel header={p.key} key={p.key}>
              {p.content}
            </Panel>
          ))}
        </Collapse>
      );
    }

    return (
      <Tabs
        defaultActiveKey="Description"
      >
        {panels.map(p => (
          <TabPane tab={p.key} key={p.key}>
            {p.content}
          </TabPane>
        ))}
      </Tabs>
    );
  }

  renderProductId = (product) => (
    <ProductId sku={product.ac || product.sku} model={product.model} secondModel={product.secondModel} mode="horizontal" />
  )

  renderJSONLD = (product) => {
    if (product.type === "configurable") {
      const productUrl = this.getUrl(product);
      const configurables = get(product, 'configurables.edges', []);

      const variesBy = [];

      get(product, 'configurableAttributes', []).forEach(({ code }) => {
        if (code === "size") {
          variesBy.push("https://schema.org/size");
        } else if (code === "color") {
          variesBy.push("https://schema.org/color");
        }
      });

      const result = (
        <Generic
          type="productGroup"
          jsonldtype="ProductGroup"
          schema={{
            productGroupID: product.model,
            name: product.name,
            model: product.model,
            image: get(product, 'mainImage.thumbnail', ''),
            description: product.metaDescription,
            url: productUrl.toString(),
            brand: {
              "@type": "Brand",
              name: get(product, 'brand.name', ''),
            },
            sku: product.sku,
            variesBy,
            hasVariant: configurables.map(({ node: p }) => this.getJsonLdSchema({
              ...p,
              brand: product.brand,
              urlSlug: product.urlSlug, // Override child urlSlug because child product could be hidden
            })),
          }}
        />
      );

      return result;
    }

    return (
      <Generic
        type="product"
        jsonldtype="Product"
        schema={this.getJsonLdSchema(product)}
      />
    );
  }

  render() {
    const { viewer, match: { location }, router } = this.props;
    const finalProduct = this.getProduct(viewer);

    const { freeShippingAmount } = viewer;
    const product = get(viewer, 'products.edges[0].node', {});
    const bonusProducts = get(finalProduct, 'bonusProducts.edges', []);
    const optionalBonus = bonusProducts.filter(edge => edge.node.optional === true);
    const nonOptionalBonus = bonusProducts.filter(edge => edge.node.optional === false);
    const suggestions = get(product.suggestions, 'edges', []);

    const productUrl = this.getUrl(product);

    return (
      <div className="product-page">
        <Helmet title={product.name}>
          <meta property="og:title" content={product.name} />
          <meta property="og:type" content="og:product" />

          <meta property="og:image" content={get(product, 'mainImage.url', '')} />

          <meta property="og:url" content={productUrl} />

          {finalProduct.price && (
          <meta property="product:price:amount" content={finalProduct.price.toFixed(2)} />
          )}

          {finalProduct.price && (
          <meta property="product:price:currency" content={process.env.CURRENCY} />
          )}

          {product.metaDescription && (
          <meta property="og:description" content={product.metaDescription} />
          )}

          {product.metaDescription && (
          <meta name="description" content={product.metaDescription} />
          )}
        </Helmet>

        <JSONLD>
          {this.renderJSONLD(product)}
        </JSONLD>

        <CmsBanner viewer={viewer} location={location} />

        <Row style={{marginTop: '5px'}}>
          {product.brand && product.brand.imageUrl && (
            <Col xs={0} sm={4} md={3} lg={2}>
              <Link
                to={`/category/by-brand/${product.brand.urlSlug}`}
                style={{
                  display: 'inline-block',
                  height: '76px',
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  width: '76px',
                  position: 'relative',
                }}
              >
                <img
                  style={imageStyle}
                  src={product.brand.imageUrl}
                  alt={product.brand.name}
                />
              </Link>
            </Col>
          )}
          <Col xs={24} sm={20} md={21} lg={22}>
            <h1 className="product-name" sku={product.sku} style={{fontWeight: 'bold', display: 'inline-block', fontSize: '28px', lineHeight: '1.1'}}>{finalProduct.name}</h1>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col xs={24} sm={12} md={15}>
            <Media
              key={product.id}
              product={product}
              selectedProduct={this.state.currentConfigProduct}
            />
          </Col>
          <Col xs={24} sm={12} md={9}>
            {isFreeShipping(product, freeShippingAmount) && (
              <div
                style={{
                  backgroundColor: '#cb0000',
                  color: 'white',
                  display: 'inline-block',
                  fontSize: '12px',
                  fontWeight: '900',
                  padding: '4px',
                  marginBottom: '5px',
                }}
              >
                FREE SHIPPING
              </div>
            )}
            {this.renderProductId(finalProduct)}
            <ShipOn product={product} />
            <GetByDate date={this.state.getByDate} />

            {renderDiscount(finalProduct)}
            {renderLoyaltyPoints(finalProduct)}

            <Price product={finalProduct} page="productview" />

            {!finalProduct.name.includes("Gift Card") && <AfterPayWidget amount={finalProduct.price} />}

            {this.state.currentConfigProduct && (
              <SelectConfigProduct
                selectedProduct={this.state.currentConfigProduct}
                product={product}
                updater={this.updateConfigProduct}
              />
            )}

            {!finalProduct.inStoreOnly && Number.isInteger(finalProduct.stockAvailable) && finalProduct.stockAvailable < 10 && (
              <div>Stock: {finalProduct.stockAvailable} Left</div>
            )}


            {finalProduct.nonStock && (
              <Link to="/p/non-stock-tc" title="Non-Stock Items Terms &amp; Conditions">Non-Stock Items Terms &amp; Conditions</Link>
            )}

            {finalProduct.onlineOnly && (
              <div style={{color: '#cb0000'}}>
                Not available for Click &amp; Collect. Please call ahead to confirm stock availability for in-store purchase.
              </div>
            )}

            {product.earnPoints === false && <div><b>This product does not incur bonus points</b></div>}
            {this.renderAddCartButton(finalProduct)}
            <WishlistBtn product={product} viewer={this.props.viewer} />
            <div className="clearfix" />

            <TierPrice product={finalProduct} />

            {this.renderPaymentLogos(finalProduct.price)}

            {bonusProducts.length > 0 && (
            <div>
              <h2>Bonuses</h2>
              {nonOptionalBonus.map((edge) => {
                const b = edge.node;
                const url = `${urlPrefix}${b.productUrlSlug}`;
                let price = '';

                if (b.price > 0) {
                  price = <span className="price"> ${b.price}</span>;
                }

                return (
                  <div key={b.productId}>
                    {b.quantity} x <a href={url} target="_blank" rel="noopener noreferrer">{b.productName}{price}</a>
                  </div>
                );
              })}

              {optionalBonus.length > 0 && (
              <div>
                <h3>Optional</h3>
                <RadioGroup defaultValue={this.state.bonusOptionId} onChange={this.bonusOptionChange} >
                  {optionalBonus.map((edge) => {
                    const b = edge.node;
                    const url = `${urlPrefix}${b.productUrlSlug}`;

                    let price = '';

                    if (b.price > 0) {
                      price = <span className="price"> ${b.price}</span>;
                    }

                    return (
                      <Radio key={b.productId} value={b.productId}>
                        {b.quantity} x <a style={{ display: 'inline-block', verticalAlign: 'top', whiteSpace: 'pre-wrap', width: '85%' }} href={url} target="_blank" rel="noopener noreferrer">{b.productName}{price}</a>
                      </Radio>
                    );
                  })}
                </RadioGroup>
              </div>
              )}
            </div>
            )}

            <Related product={product} viewer={viewer} />

            <div>
              {!this.shouldDisableButton() && (
                <Freebie product={finalProduct} />
              )}
            </div>

            <ProductStock viewer={viewer} product={finalProduct} match={this.props.match} />

            <div style={{border: '1px solid rgba(0, 0, 0, 0.1)', marginTop: '5px'}}>
              <ShippingQuote product={finalProduct} viewer={viewer} handleGetByDate={this.handleGetByDate} />
            </div>


            <Row>
              <Col xs={12}>
                <img className="img-fluid" style={{height: '32px', marginTop: '10px', paddingRight: '10px'}} alt="Best Prices Guaranteed" src="/static/images/best_prices_guaranteed.png" />
              </Col>
              <Col xs={12}>
                <PriceMatch product={finalProduct} viewer={viewer} />
              </Col>
            </Row>
          </Col>

        </Row>

        {suggestions.length > 0 && (
        <div className="full-width" style={{backgroundColor: '#f1f1f1'}} data-nosnippet="true">
          <div style={{maxWidth: '1120px', margin: '0 auto', paddingTop: '5px', paddingBottom: '5px'}}>
            <List viewer={viewer} data={suggestions} listMode="suggestions" router={router} />
          </div>
        </div>
        )}

        <MediaQuery maxWidth={767} >
          { this.renderPanels(viewer, product, 'collapse') }
        </MediaQuery>

        <MediaQuery minWidth={768} >
          { this.renderPanels(viewer, product, 'tabs') }
        </MediaQuery>

      </div>
    );
  }
}

/*
 * Adding the field: priorityShipping { getBydate } under products node,
 * will cause relay store to crash when ChangeItemQty / AddProductToCart Mutation are applied,
 * when priorityShipping changes to/from `null` and `{ getByDate: 'date' }`.
*/
export default createFragmentContainer(ProductView, {
  viewer: graphql`
    fragment ProductView_viewer on Customer {
      ...CmsBanner_viewer
      ...ShippingQuote_viewer
      ...ReviewList_viewer
      ...List_viewer
      ...PriceMatch_viewer
      ...ProductStock_viewer
      ...Related_viewer
      ...WishlistBtn_viewer
      email
      salesNumber
      freeShippingAmount
      products(first: 1, urlSlugs: $urlSlugs) {
        edges {
          node {
            id
            alerts
            name
            type
            brand {
              id
              imageUrl
              name
              urlSlug
            }
            mainImage {
              id
              url
              thumbnail
            }
            files(first: 99) {
              edges {
                node {
                  id
                  name
                  url
                }
              }
            }
            images {
              url
              thumbnail
            }
            model
            bundles(first: 99) {
              edges {
                node {
                  id
                  name
                  model
                  sku
                  ac
                  description
                  specification
                  warranty
                  urlSlug
                  quantity
                  images(showInKit: true) {
                    url
                    thumbnail
                  }
                }
              }
            }
            configurableAttributes
            configurables(first: 99) {
              edges {
                node {
                  id
                  name
                  type
                  attributes
                  model
                  secondModel
                  sku
                  ac
                  regularPrice
                  price
                  priceMatch
                  description
                  specification
                  warranty
                  urlSlug
                  quantity
                  sellable
                  nonStock
                  onlineOnly
                  clearance
                  callForPrice
                  inStoreOnly
                  sortingFactor
                  videos
                  backInStockDate
                  stockAvailable
                  preorderDate
                  globalFreeShipping
                  freeShipping
                  ...TierPrice_product
                  tierPrices
                  discountCode
                  loyaltyPoints {
                    accruing
                    dollars
                  }
                  images(showInKit: true) {
                    url
                    thumbnail
                  }
                  metaDescription
                  status
                  labels(first: 999) {
                    edges {
                      node {
                        id
                        imageUrl
                        productPageImageUrl
                        placement
                      }
                    }
                  }
                  bonusProducts(first: 999) {
                    edges {
                      node {
                        productId
                        productName
                        productUrlSlug
                        price
                        quantity
                        optional
                      }
                    }
                  }
                  ...PriceMatch_product
                  ...ReviewForm_product
                  ...ReviewList_product
                  ...ProductStock_product
                  ...Freebie_product
                }
              }
            }
            secondModel
            sortingFactor
            sku
            ac
            description
            specification
            urlSlug
            type
            regularPrice
            price
            priceDrop
            nonStock
            onlineOnly
            earnPoints
            videos
            backInStockDate
            stockAvailable
            preorderDate
            globalFreeShipping
            freeShipping
            sellable
            clearance
            callForPrice
            inStoreOnly
            ...TierPrice_product
            tierPrices
            warranty
            discountCode
            loyaltyPoints {
              accruing
              dollars
            }
            metaDescription
            status
            commonPromoBanners
            labels(first: 999) {
              edges {
                node {
                  id
                  imageUrl
                  productPageImageUrl
                  placement
                }
              }
            }
            bonusProducts(first: 999) {
              edges {
                node {
                  productId
                  productName
                  productUrlSlug
                  price
                  quantity
                  optional
                }
              }
            }
            suggestions(first: 12) {
              edges {
                node {
                  id
                  alerts
                  name
                  model
                  secondModel
                  price
                  sku
                  ac
                  urlSlug
                  freeShipping
                  preorderDate
                  backInStockDate
                  callForPrice
                  inStoreOnly
                  onlineOnly
                  discountCode
                  nonStock
                  sellable
                  clearance
                  brand {
                    id
                    name
                  }
                  labels(first: 999) {
                    edges {
                      node {
                        id
                        type
                        imageUrl
                        placement
                      }
                    }
                  }
                  mainImage {
                    id
                    thumbnail
                  }
                }
              }
            }
            ...PriceMatch_product
            ...ReviewForm_product
            ...ReviewList_product
            ...ProductStock_product
            ...Freebie_product
            ...Related_product
            ...WishlistBtn_product
          }
        }
      }
    }
  `,
});
