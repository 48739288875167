import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';

import { debounce, get, uniqBy } from 'lodash';
import { AutoComplete, Button, Form, message, Select } from 'antd';
import Helmet from '~/components/page/Helmet';

import { PriceTagMutation } from './mutations';

const { Item: FormItem } = Form;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 8 },
    sm: { span: 8 },
    md: { span: 8 },
    lg: { span: 8 },
  },
};

/*
 * `<Select>` is required to let user select the option more than once, i.e.
 * scanning a product twice to have two price tags print out.
 * As a workaround, all `key`s in `<Option>` are suffixed with ``${key}{SEPARATOR}${epoch}``.
 * So it is necessary to remove the suffix before submitting.
 */
const SEPARATOR = '____';

class PriceTag extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      searchProducts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      stores: PropTypes.shape({
        edgess: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      storeId: null,
      value: "",
    }
  }

  fetchProductByBarcode = (barcode, cb = null) => {
    this.props.relay.refetch({
      first: 10,
      query: "",
      orderBy: null,
      filters: JSON.stringify({barcode})
    }, null, cb);
  }

  fetchProduct = debounce((value) => {
    this.props.relay.refetch({
      first: 40,
      query: value,
      orderBy: null,
      // Control how search works, originally price-tag is using the same idea of a normal search
      // However, stores need to print price tags for children of configurables and the configurables
      // itself should not be returned.
      filters: JSON.stringify({_type: "price-tag"})
    }, null, () => {
      const { current: form } = this.formRef;
      const { viewer } = this.props;
      const searchProducts = get(viewer, 'searchProducts.edges', []);

      if (searchProducts.length === 1) {
        let productIds = form.getFieldValue('productIds') || [];
        const product = get(searchProducts, '[0].node', {});

        const option = (
          <Option key={`${product.id}${SEPARATOR}${Date.now()}`} title={product.name}>
            {this.productOption(product)}
          </Option>
        )

        productIds.push({
          key: option.key,
          label: option.props.children,
        });

        productIds = uniqBy(productIds, 'key');

        form.setFieldsValue({
          productIds
        });
        this.clearValue();
      }
    });
  }, 100)

  handleSubmit = (values) => {
    const regex = new RegExp(`${SEPARATOR}.+$`);
    values.productIds = (values.productIds || []).map(p => p.key.replace(regex, ''));
    values.storeId = this.state.storeId;

    PriceTagMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        if (resp.priceTag.result) {
          message.success("Success, Generating Price Tag. . .", 10);
        }
      },
      onError: (errors) => {
        message.error(errors[0].message);
      },
    });
  }

  handleSelect = (value, option) => {
    this.setState({
      storeId: option.key,
    });
  }

  productOption = (p) => (
    <div>
      <img width="20" height="20" src={p.mainImage && p.mainImage.url} alt="" />
      {p.name}
    </div>
  )

  clearValue = () => {
    this.setState({ value: "" });
  }

  renderOption = (stores) => {
    return stores.map(({node}) => {
      return {
        key: node.id,
        value: node.name,
      }
    });
  }

  render() {
    const { viewer } = this.props;
    const searchProducts = get(viewer, 'searchProducts.edges', []);
    const stores = get(viewer, 'stores.edges', []);

    return (
      <div style={{padding: '10px'}}>
        <Helmet title="Price Tag" />
        <h1>Price Tag</h1>

        <span style={{color: '#cb0000'}}>
          Products in your <b>Cart</b> and <b>Manual Selections</b> both will be printed.
        </span>

        <Form ref={this.formRef} onFinish={this.handleSubmit}>
          <FormItem
            label="Manual Selections"
            name="productIds"
            rules={[
              { required: false, message: 'Required'},
            ]}
            initialValue={[]}
          >
            <Select
              allowClear
              autoFocus
              defaultActiveFirstOption={false}
              placeholder="Search By Barcode"
              mode="multiple"
              labelInValue
              filterOption={false}
              onSearch={(value) => {
                this.setState({ value });
                this.fetchProduct(value);
              }}
              onSelect={this.clearValue}
              onDeselect={this.clearValue}
              onBlur={this.clearValue}
              searchValue={this.state.value}
            >
              {searchProducts.map((d) => {
                const p = d.node;
                return (
                  <Option key={`${p.id}${SEPARATOR}${Date.now()}`} title={p.name}>
                    {this.productOption(p)}
                  </Option>
                )
              })}
            </Select>
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Store"
            name="storeId"
            rules={[
              { required: true, message: 'Required'},
            ]}
          >
            <AutoComplete
              allowClear
              options={this.renderOption(stores)}
              onSelect={this.handleSelect}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              placeholder="Store Name"
            />
          </FormItem>

          <Button type="primary" htmlType="submit">
            Generate Price Tag
          </Button>
        </Form>

      </div>
    )
  }
}

export default createRefetchContainer(PriceTag, {
    viewer: graphql`
    fragment PriceTag_viewer on Customer @argumentDefinitions(
      first: {type: "Int", defaultValue: 40},
      query: {type: "String", defaultValue: ""},
      orderBy: {type: OrderBy, defaultValue: null},
      filters: {type: String, defaultValue: null},
    ) {
      id
      stores(first: 9999, filterBy: $filterBy) {
        edges {
          node {
            id
            name
          }
        }
      }
      searchProducts: products(first: $first, query: $query, orderBy: $orderBy, filters: $filters) {
        edges {
          node {
            id
            name
            type
            attributes
            sku
            status
            mainImage {
              id
              url
            }
          }
        }
      }
    }
  `,
  },
  graphql`
  query PriceTagRefetchQuery($first: Int, $query: String, $orderBy: OrderBy, $filters: String) {
    viewer {
      searchProducts: products(first: $first, query: $query, orderBy: $orderBy, filters: $filters) {
        edges {
          node {
            id
            name
            type
            attributes
            sku
            status
            mainImage {
              id
              url
            }
          }
        }
      }
    }
  }
`,
);
