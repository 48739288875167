import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'found';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { CaretLeftOutlined, FacebookOutlined, InstagramOutlined, YoutubeOutlined } from '@ant-design/icons';
import { Col, Collapse, Row } from 'antd';
import { get } from 'lodash';
import MediaQuery from 'react-responsive';
import { getSocials, getSupportNumber } from '~/helper';
import StoreSearch from '../store/StoreSearch';
import SubscriptionForm from './SubscripitonForm';

import './style.css';

const { Panel } = Collapse;

const titleStyle = {
  fontWeight: '900',
  fontSize: '14px',
  lineHeight: '18px',
}

const redTitle = {
  color: '#CB0000',
  ...titleStyle
}

const whiteTitle = {
  color: '#FFFFFF',
  ...titleStyle
}

const mediaStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: "#CB0000",
  color: 'white',
  width: '44px',
  height: '44px',
  marginRight: '10px'
}

const supportNumber = getSupportNumber().key;
const social = getSocials();

let paymentImages = [
  {key: "visa", name: "Visa", file: "visa_50x33.svg"},
  {key: "mastercard", name: "Mastercard", file: "mastercard_50x33.svg"},
  {key: "amex", name: "Amex", file: "americanexpress_50x33.svg"},
  {key: "zip", name: "Zip", file: "zip_50x33.svg"},
  {key: "paypal", name: "Paypal", file: "paypal_50x33.svg"},
  {key: "applepay", name: "Apple Pay", file: "applepay_50x33.svg"},
  {key: "googlepay", name: "Google Pay", file: "googlepay_50x33.svg"},
  {key: "humm", name: "Humm", file: "humm_50x33.svg"},
  {key: "afterpay", name: "AfterPay", file: "afterpay.svg"},
];

let company = "Sydney Tools Pty Ltd";

// FIXME not yet finalise
if (process.env.COUNTRY === 'NZ') {
  paymentImages = [
    {key: "visa", name: "Visa", file: "visa_50x33.svg"},
    {key: "mastercard", name: "Mastercard", file: "mastercard_50x33.svg"},
    {key: "amex", name: "Amex", file: "americanexpress_50x33.svg"},
    {key: "afterpay", name: "AfterPay", file: "afterpay.svg"},
    {key: "zip", name: "Zip", file: "zip_50x33.svg"},
  ];

  company = "Sydney Tools (NZ) Limited";
}

const FooterSection = (props) => {
  const { footer, heading } = props;

  if (!footer) {
    return null;
  }

  return (
    <>
      {heading && <h3 style={redTitle}>{footer.name}</h3>}
      <ul style={{ listStyle: 'none', padding: '0', fontSize: '13px' }}>
        {get(footer, 'urls', []).map((i) => {
          let properties = get(i, 'properties') || "{}";
          properties = JSON.parse(properties);

          return (
            <li key={i.name}>
              {i.type === "link" && (
                <Link to={i.url} {...properties}>{i.name}</Link>
              )}
              {i.type === "href" && (
                <a href={i.url} {...properties}>{i.name}</a>
              )}
            </li>
          )
        })}
      </ul>
    </>
  )
}

FooterSection.propTypes = {
  footer: PropTypes.shape({
    name: PropTypes.string,
  }),
  heading: PropTypes.bool,
}

FooterSection.defaultProps = {
  footer: null,
  heading: false,
}

class Footer extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  renderStayConnected = () => {
    return (
      <div
        className="stay-connected"
        style={{ display: 'flex', marginBottom: '15px', flexDirection: 'column' }}
      >
        <h3 style={redTitle}>Stay Connected</h3>

        <div style={{ display: 'flex' }}>
          <a style={mediaStyle} href={social.facebook}>
            <FacebookOutlined />
          </a>

          <a style={mediaStyle} href={social.instagram}>
            <InstagramOutlined />
          </a>

          <a style={mediaStyle} href={social.youtube}>
            <YoutubeOutlined style={{ fontSize: '95%' }} />
          </a>

          <a style={mediaStyle} href={social.tiktok}>
            <img src="/static/images/footer/tiktok.svg" alt="Tiktok" style={{ width: '36px' }} />
          </a>
        </div>
      </div>
    );
  }

  renderPayments = () => {
    return (
      <Row className="footer-payment" type="flex" gutter={[5, 0]} style={{ paddingBottom: '5px' }}>
        {paymentImages.map(i => {
          return (
            <Col key={i.key}>
              <img style={{ width: '33px' }} className="img-fluid" alt={i.name} src={`/static/images/footer/${i.file}`} />
            </Col>
          )
        })}
      </Row>
    )
  }

  render() {
    const { viewer } = this.props;
    const footers = get(viewer, 'footers.edges', []);

    return (
      <div id="footer" className="footer" style={{ color: 'white', zIndex: '2' }}>
        <div style={{ backgroundColor: '#252525', padding: '5px 15px' }}>
          <Row type="flex" align="middle" style={{ maxWidth: '1120px', margin: '0 auto' }}>
            <Col xs={24} sm={14} md={12}>
              <div style={{ display: 'block' }}>
                <h3 style={{ display: 'inline-block', marginRight: '10px', ...whiteTitle }}>Newsletter</h3>
                <p style={{ display: 'inline-block', fontSize: '12px', lineHeight: '15px', marginBottom: '4px' }}>Subscribe to receive special offers and deals</p>
              </div>
              <SubscriptionForm />
            </Col>
            <Col className="footer-center footer-end-padding" xs={24} sm={10} md={12}>
              <div style={{ display: 'block', marginBottom: '10px', lineHeight: '18px' }}>
                <a title="Call Now" style={whiteTitle} href={`tel:${supportNumber}`}>
                  Sales &#38; Online Support: <span style={{fontWeight: '700', whiteSpace: 'nowrap'}}>{supportNumber}</span>
                </a>
              </div>
              <img className="img-fluid" style={{maxWidth: '280px', paddingBottom: '5px'}} alt="Best Prices Guaranteed" src="/static/images/footer/best_price_guaranteed.svg" />
            </Col>
          </Row>
        </div>

        <div style={{padding: '10px 15px 0px 15px'}}>
          <Row style={{ maxWidth: '1120px', margin: '0 auto' }}>
            <Col xs={24}>
              <Row>
                <Col xs={0} sm={24} md={16}>
                  <Row>
                    <Col sm={8}>
                      <FooterSection footer={get(footers, '[0].node')} heading />
                    </Col>
                    <Col sm={9}>
                      <FooterSection footer={get(footers, '[1].node')} heading />
                    </Col>
                    <Col sm={7}>
                      <FooterSection footer={get(footers, '[2].node')} heading />
                    </Col>
                  </Row>
                </Col>

                <Col xs={24} sm={0}>
                  <Collapse
                    accordion
                    style={{ backgroundColor: 'white', border: 'none', marginBottom: '20px' }}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => <CaretLeftOutlined rotate={isActive ? 270 : 180} />}
                  >
                    {footers.map(({ node }) => (
                      <Panel key={node.name} header={node.name}>
                        <FooterSection footer={node} />
                      </Panel>
                    ))}
                  </Collapse>
                </Col>

                <Col xs={24} md={8}>
                  <Row gutter={[16, 10]}>
                    <Col xs={24} sm={12} md={24}>
                      <StoreSearch viewer={viewer} router={this.props.router} />
                    </Col>
                    <Col xs={24} sm={12} md={24}>
                      {this.renderStayConnected()}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div style={{ backgroundColor: '#cb0000', padding: '0px 15px'}}>
          <Row type="flex" style={{ padding: '10px 0px', maxWidth: '1120px', margin: '0 auto' }}>
            <Col className="payment-center" xs={24} sm={{span: 12, push: 12}} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '15px' }}>
              <MediaQuery minWidth={470}>
                <span style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'right', ...whiteTitle }}>Payments: </span>
              </MediaQuery>
              {this.renderPayments()}
            </Col>
            <Col className="footer-center" xs={24} sm={{span: 12, pull: 12}}>
              <img alt="Logo" style={{maxWidth: '300px', padding: '10px 10px 5px 0px'}} src="/static/images/logo_flat.svg" />
              <span style={{ display: 'block', fontSize: '12px' }}>&#169; {new Date().getFullYear()} {company}</span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(Footer, {
  viewer: graphql`
    fragment Footer_viewer on Customer {
      footers(first: 3) {
        edges {
          node {
            name
            urls {
              name
              type
              url
              properties
            }
          }
        }
      }
      ...StoreSearch_viewer
    }
  `,
});
