import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, message, Select, Space, Radio, Upload } from 'antd';
import Helmet from '~/components/page/Helmet';
import { PostJssFormMutation } from './mutations';

const { Item: FormItem } = Form;
const { TextArea } = Input;
const { Option } = Select;
const { Group: RadioGroup } = Radio;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 24 },
    lg: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 24 },
    lg: { span: 14 },
  },
};

// Using antd v4 form

class Jss extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    viewer: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleSubmit = (values) => {
    const { current: form } = this.formRef;
    const { viewer, relay: { environment }} = this.props;

    const uploadables = {};

    if (values.attachments) {
      const fileList = values.attachments.map(f => f.originFileObj);

      values.attachments = fileList.map((f, i) => {
        const name = `attachment${i}`;

        uploadables[name] = f;
        return name;
      });
    }

    PostJssFormMutation.commit({
      environment,
      variables: { input: values },
      uploadables,
      viewer,
      onCompleted: (resp) => {
        if (resp.postJssForm.result) {
          message.success("Success");
          form.resetFields();
        }
      },
      onError: (errors) => {
        message.error(errors[0].message);
      },
    })
  }

  render() {
    const { viewer } = this.props;
    const brands = get(viewer, 'categories.edges[0].node.brands.edges', []);
    const stores = get(viewer, 'stores.edges', []);

    return (
      <div style={{ padding: '10px' }}>
        <Helmet title="JSS" />
        <h1>JSS Form</h1>

        <Form ref={this.formRef} onFinish={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            name="brand_ids"
            label="Select Brand(s)"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              mode="multiple"
              optionFilterProp="children"
              allowClear
            >
              {brands.map(({node: b}) => (<Option key={b.id} value={b.id}>{b.name}</Option>))}
            </Select>
          </FormItem>

          <FormItem
            {...formItemLayout}
            name="name"
            label="Customer Name"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </FormItem>

          <FormItem
            {...formItemLayout}
            name="phone"
            label="Customer Phone Number"
            rules={[
              { type: "string", required: true, pattern: /^\+?[()+0-9 ext.]+$/, message: 'Phone Number Only' },
              { required: true, message: 'Required' }
            ]}
          >
            <Input />
          </FormItem>

          <FormItem
            {...formItemLayout}
            name="quoteNumber"
            label="Quote Number"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </FormItem>

          <FormItem
            {...formItemLayout}
            name="quoteValue"
            label="Potential Order Value"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber style={{ width: '100%' }} prefix="$" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            name="storeId"
            label="Store"
            rules={[{ required: true, message: 'Required'}]}
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
            >
              {stores.map(({node: s}) => (<Option key={s.id} value={s.id}>{s.name}</Option>))}
            </Select>
          </FormItem>

          <FormItem
            {...formItemLayout}
            name="conversion"
            label="Select if this a"
          >
            <RadioGroup>
              <Space direction="vertical">
                <Radio value="Cold Lead">Cold Lead</Radio>
                <Radio value="Warm Lead">Warm Lead</Radio>
                <Radio value="Hot Lead">Hot Lead</Radio>
              </Space>
            </RadioGroup>
          </FormItem>

          <FormItem
            {...formItemLayout}
            name="attachments"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
            label="List of tools provided by the customer"
            extra="Files can be up to 2MB for file types (.pdf .doc .docx .png .jpeg)"
          >
            <Upload
              accept=".pdf,.doc,.docx,image/png,image/jpeg"
              listType="picture"
              customRequest={() => {}}
              beforeUpload={() => false}
            >
              <Button>
                <UploadOutlined /> Attachments
              </Button>
            </Upload>
          </FormItem>

          <FormItem
            name="comments"
          >
            <TextArea placeholder="Comments" rows={4} />
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </div>
    )
  }
}

export default createFragmentContainer(Jss, {
  viewer: graphql`
    fragment Jss_viewer on Customer {
      id
      stores(first: 9999) {
        edges {
          node {
            id
            name
          }
        }
      }
      categories(first: 1, urlSlug: "by-brand") {
        edges {
          node {
            id
            brands(first: 999) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
});
