import React from "react";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import { Input } from "antd";
import { validateCvv } from "../CreditCard";

const { Item: FormItem } = Form;

export default class SavedCard extends React.Component {
  static propTypes = {
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    method: PropTypes.shape({
      code: PropTypes.string.isRequired,
      extra: PropTypes.shape({
        cvvRequired: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    node: PropTypes.shape({
      id: PropTypes.string.isRequired,
      ccBrand: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { form, node, method } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        <div style={{ display: "none" }}>
          {getFieldDecorator("ccType", {
            initialValue: node.ccBrand,
          })(<div />)}
        </div>

        {method.extra.cvvRequired && (
          <FormItem hasFeedback>
            {getFieldDecorator("ccCvv", {
              rules: [
                { required: true, message: "Required" },
                { validator: validateCvv.bind(this, form) },
              ],
            })(<Input placeholder="CVV is required" maxLength={4} />)}
          </FormItem>
        )}
      </>
    );
  }
}
