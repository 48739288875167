import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import MediaQuery from 'react-responsive';

import {
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  MinusOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Menu, Row, Tooltip } from 'antd';

import { Link } from 'found';
import { camelCase, get, groupBy, sortBy } from 'lodash';
import { getProductUrl, getThumbnail } from '~/helper';
import GA4 from '~/ga4';
import { getTnCPath } from '~/components/cms/routes';

import {
  ChangeItemQtyMutation,
  EmptyCartMutation,
  RemoveProductFromCartMutation,
} from './mutations';

import { checkoutEnv } from '../checkout/helper';
import FreebiePopup from '../product/FreebiePopup';
import { getStoreId } from '../pos/Pos';

import Service, { COLOR } from './Service';

import './style.css';
import { getStockInfo } from '../checkout/StockInfo';

const getBorders = (color) => {
  const top = `inset 0 2px 0 0 ${color}`;
  const right = `inset 2px 0 0 0 ${color}`;
  const bottom = `inset 0 -2px 0 0 ${color}`;
  const left = `inset -2px 0 0 0 ${color}`;

  return [top, right, bottom, left];
};

class ItemList extends React.Component {
  static propTypes = {
    handleQtyChange: PropTypes.func,
    form: PropTypes.shape({
      getFieldValue: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
    }),
    viewer: PropTypes.shape({
      cart: PropTypes.shape({
        lines: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        }).isRequired,
        storeCredits: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        }).isRequired,
        subtotal: PropTypes.number,
      }),
    }),
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    showActionButtons: PropTypes.bool,
    handleMenuClick: PropTypes.func,
    style: PropTypes.shape({}),
    dest: PropTypes.shape({
      postcode: PropTypes.string,
      suburb: PropTypes.string,
    })
  }

  static defaultProps = {
    form: null,
    viewer: null,
    handleQtyChange: null,
    style: {},
    showActionButtons: true,
    handleMenuClick: null,
    dest: null,
  }

  getHeaderAndBorder = (showPriority, shippingMethod, store, key) => {
    const { form } = this.props;
    let header = null;
    const borderStyle = {};

    if (showPriority && form) {
      if (shippingMethod === 'priority') {
        if (['normal', 'nonStock'].includes(key)) {
          header = <Service.Standard type={key} />
        } else {
          header = <Service.Priority getByDate={key} />
        }
      }

      if (shippingMethod === 'normal') {
        if (key === 'nonStock') {
          header = <Service.Standard type={key} />
        } else {
          header = <Service.Standard type="normal" />
        }
      }

      if (shippingMethod === 'priority' || shippingMethod === 'normal') {
        const color = ['normal', 'nonStock'].includes(key) ? COLOR.standard : COLOR.priority;

        borderStyle.boxShadow = getBorders(color).join();
      }

      if (shippingMethod === 'storepickup' && store) {
        const stocks = get(store, 'stocks', []);
        const { text, backgroundColor } = getStockInfo(stocks);

        header = (
          <Service style={{ backgroundColor }}>
            <span style={{ fontSize: '14px' }}>{text}</span>
          </Service>
        );

        borderStyle.boxShadow = getBorders(backgroundColor).join();
      }
    }

    if (header) {
      header = (
        <div style={{ height: '35px', width: '190px', fontSize: '12px', fontWeight: '700', lineHeight: '1', marginTop: '5px' }}>
          {header}
        </div>
      )
    }

    return { header, borderStyle }
  }

  getGroupedLines = (lines, shippingMethod) => {
    // sort then group lines by priority shipping
    const sortedLines = sortBy(lines, [({ node }) => {
      const gbd = get(node, 'product.priorityShipping.getByDate');
      return Date.parse(new Date(gbd));
    }]);

    let grouped = groupBy(sortedLines, ({ node }) => {
      const gbd = get(node, 'product.priorityShipping.getByDate');
      const type = this.getType(node);

      if (shippingMethod === 'priority') {
        return gbd ?? type;
      } else if (shippingMethod === 'normal') {
        return type;
      }

      return !!gbd;
    });

    if (shippingMethod === 'storepickup') {
      const t = get(grouped, 'true', []);
      const f = get(grouped, 'false', []);

      grouped = { 'true': t.concat(f) };
    }

    const groupedKeys = Object.keys(grouped);
    const groupedLines = sortBy(groupedKeys, [(k) => {
      const unixTime = new Date(k).getTime();
      const max = Number.MAX_SAFE_INTEGER;

      if (k === 'false') {
        return max;
      } else if (k === 'true') {
        return max - 1;
      }

      if (k === 'nonStock') {
        return max;
      } else if (k === 'normal') {
        return max - 1;
      } else if (!Number.isNaN(unixTime)) {
        return unixTime;
      }

      return Infinity;
    }]);

    return { grouped, groupedLines };
  }

  getShippingMethod = () => {
    const { form } = this.props;

    if (!form) {
      return null;
    }

    const shippingMethod = form.getFieldValue("shippingMethod");

    if (shippingMethod === 'storepickup') {
      return shippingMethod;
    } else if (!shippingMethod || shippingMethod?.includes('priority')) {
      return 'priority';
    } else if (shippingMethod) {
      return 'normal';
    }

    return null;
  }

  getType = (l) => this.isNonStock(l) ? 'nonStock' : 'normal';

  handleRemove = (line) => {
    const handleRemove = checkoutEnv.getFn('handleRemove');
    if (handleRemove) {
      handleRemove(line);
    } else {
      RemoveProductFromCartMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: { id: line.id } },
        viewer: this.props.viewer,
        onCompleted: () => {
          GA4.removeFromCart(line, line.quantity)
        }
      });
    }
  }

  handleQtyChange = (line, type) => {
    const handleQtyChange = checkoutEnv.getFn('handleQtyChange');
    if (this.props.handleQtyChange) {
      this.props.handleQtyChange(line, type);
    } else if (handleQtyChange) {
      handleQtyChange(line, type);
    } else {
      ChangeItemQtyMutation.commit({
        environment: this.props.relay.environment,
        variables: {
          input: {
            id: line.id,
            type,
            qty: 1,
          },
        },
        viewer: this.props.viewer,
        onCompleted: () => {
          if (type === 'dec') {
            GA4.removeFromCart(line);
          } else if (type === 'inc') {
            GA4.addToCart(line);
          }
        },
      });
    }
  }

  handleEmptyCart = (cart) => {
    EmptyCartMutation.commit({
      environment: this.props.relay.environment,
      viewer: this.props.viewer,
      onCompleted: () => {
        GA4.removeAllFromCart(cart);
      }
    });

  }

  isNonStock = (l) => (l.product.nonStock || l.product.preorderDate || l.product.backInStockDate)

  renderStockInfo = (l) => {
    const { viewer, form } = this.props;

    if (!form || l.name.includes("[Bonus]") || l.isFreebie) {
      return null;
    }

    const { shippingMethod, pickupStoreId } = form.getFieldsValue(['shippingMethod', 'pickupStoreId']);

    if (shippingMethod === 'storepickup') {
      const store = get(viewer, 'cart.stores.edges', []).find(({ node }) => node.id === pickupStoreId);
      const { stock } = get(store, 'node.stocks', []).find(({ productId }) => productId === l.product.id) || {};

      if (stock) {
        return (
          <div
            className={camelCase(stock)}
            style={{ display: 'inline-block', float: 'right', fontSize: '12px', fontWeight: 700 }}
          >
            <span>{stock}</span>
          </div>
        )
      }
    }

    return null;
  }

  render() {
    const { form, viewer, showActionButtons } = this.props;

    /*
     * Viewer will becomes momentary null.
     * When `CheckoutView` request a refetch right after a new auth_token is set.
     * Steps to produce (Scenario: New User & All required fields are filled in):
     * 1. When Place Order is Clicked, Back-End throw an error
     * 2. Change to any Payment method twice.
     */
    if (!viewer) {
      return null;
    }

    const cart = get(viewer, 'cart', {});
    const lines = get(cart, 'lines.edges', []);
    const shippingMethod = this.getShippingMethod();

    const { grouped, groupedLines } = this.getGroupedLines(lines, shippingMethod);

    const credits = get(cart, 'storeCredits.edges', []).filter(({ node }) => node.stealthMode === false);

    const loyaltyPoints = get(cart, 'loyaltyPoints', {});

    const cartListScroll = showActionButtons ? "cart-list-scroll" : null
    const menuItemStyle = { padding: '8px 16px 0px 16px', lineHeight: '1' };
    const showPriority = get(viewer, 'configs.priority', false);

    const stores = get(viewer, 'cart.stores.edges', []);
    const pickupStoreId = form?.getFieldValue('pickupStoreId');
    const { node: store } = stores.find(({ node }) => node.id === pickupStoreId) || {};

    const menu = (
      <Menu className="cart" selectable={false} style={this.props.style}>
        {showActionButtons && (
          <MediaQuery maxWidth={575}>
            <Menu.Item key="close" style={{ margin: '0px', padding: '0px 16px' }}>
              <h3 style={{ margin: '0px', display: 'inline-block' }}>Shopping Cart</h3>
              <Button type="link" style={{ padding: '0 10px', height: 'inherit', position: 'absolute', right: '0' }} onClick={this.props.handleMenuClick}>
                <CloseOutlined style={{ margin: '0px' }} />
              </Button>
            </Menu.Item>
          </MediaQuery>
        )}

        {(lines.length === 0) && (
          <Menu.Item key="empty">
            Your cart is empty.
          </Menu.Item>
        )}

        {(lines.length > 0) && (
          <Menu.Item
            key="cart_list"
            className={cartListScroll}
            style={{ height: 'auto', padding: '0px', margin: '0px', whiteSpace: 'initial' }}
          >
            {credits.map((edge) => {
              const c = edge.node;
              return (
                <div key={c.id} style={menuItemStyle}>
                  <div style={{ color: '#5cb85c' }}>
                    You will receive <b>${c.creditAmount}</b> as points as part of the <b>{c.name}</b>.
                  </div>
                </div>
              );
            })}

            {credits.length > 0 && (
              <div style={menuItemStyle}>
                <div style={{ color: '#5cb85c' }}>
                  Please complete checkout to receive your points.<br />
                </div>
                <div style={{ color: '#cb0000', margin: '5px 0' }}>
                  Points will be allocated in store for Click and Collect orders, (Not online).
                </div>
              </div>
            )}

            {loyaltyPoints.accruing > 0 && (
              <div style={menuItemStyle}>
                <div style={{ color: '#5cb85c' }}>
                  You will receive <b>{loyaltyPoints.accruing}</b> loyalty points.
                  <Link style={{marginLeft: '5px'}}to={getTnCPath("points")}><QuestionCircleOutlined title="Learn More" /></Link>
                </div>
              </div>
            )}

            {groupedLines.map((k, kIndex) => {
              const items = grouped[k].map(({ node: l }, i) => {
                const thumbnail = getThumbnail(l);
                const url = getProductUrl(l, "/product/");

                return (
                  <div key={l.id} style={menuItemStyle}>
                    <Row>
                      <Col xs={8} sm={6} >
                        <Link to={url} onClick={this.props.handleMenuClick}>
                          <img alt={l.name} className="img-fluid" src={thumbnail} />
                        </Link>
                      </Col>

                      <Col xs={16} sm={18} >
                        <Row>
                          <Col span={22}>
                            <div style={{ whiteSpace: 'pre-wrap', paddingLeft: '5px', lineHeight: '20px' }}>
                              <Link className="black-href" to={url} sku={l.product.sku} style={{ fontSize: '12px', fontWeight: 'bold' }} onClick={this.props.handleMenuClick}>
                                {l.name}
                              </Link>
                            </div>
                          </Col>
                          {!l.isFreebie && (
                            <Col span={2} style={{ lineHeight: '1' }}>
                              <Button size="small" type="link" style={{ padding: '0', verticalAlign: 'top' }} onClick={() => { this.handleRemove(l); }}> <DeleteOutlined /></Button>
                            </Col>
                          )}

                          <Col span={24} style={{ lineHeight: '30px' }}>
                            <div style={{ paddingLeft: '5px', textAlign: 'right' }}>
                              {l.unitDiscount > 0 && (
                                <div style={{ display: 'inline-flex', overflowWrap: 'break-word' }}>
                                  <span className="price" style={{ marginLeft: '10px', textDecoration: 'line-through' }}>${l.unitPrice.toFixed(2)}</span>
                                </div>
                              )}
                              <div style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                {l.quantity} X <span className={`price ${l.unitDiscount > 0 ? 'discount' : ''}`}>${(l.unitPrice - l.unitDiscount).toFixed(2)}</span>
                              </div>
                            </div>
                          </Col>

                          {l.product.onlineOnly && (
                            <Col span={24} style={{ textAlign: 'right', lineHeight: '20px' }}>
                              <span style={{ color: '#cb0000', fontSize: '11px' }}>
                                Not available for Click &amp; Collect
                              </span>
                            </Col>
                          )}

                          {this.isNonStock(l) && (
                            <Col span={24} style={{ textAlign: 'right', lineHeight: '20px' }}>
                              <span style={{ color: '#cb0000', fontSize: '11px' }}>
                                This item might affect overall delivery time.
                                <Tooltip title="We currently do not have this item in stock.">
                                  <ExclamationCircleFilled style={{ margin: '0px 0px 0px 2px' }} />
                                </Tooltip>
                              </span>
                            </Col>
                          )}

                          {l.product.limitedStock && (
                            <Col span={24}>
                              <div style={{ paddingLeft: '5px', lineHeight: '1' }}>
                                <span style={{ color: '#cb0000', fontSize: '11px' }}>
                                  This item might affect your shipping options.
                                </span>
                              </div>
                            </Col>
                          )}

                          {!l.isFreebie && (
                            <Col span={24}>
                              <div style={{ paddingLeft: '5px', lineHeight: '1' }}>
                                <Button size="small" onClick={() => { this.handleQtyChange(l, 'dec'); }} style={{ padding: '0 13px' }}>
                                  <MinusOutlined />
                                </Button>
                                <Button size="small" onClick={() => { this.handleQtyChange(l, 'inc'); }} style={{ margin: '0 3px', padding: '0 13px' }}>
                                  <PlusOutlined />
                                </Button>
                              </div>
                              {this.renderStockInfo(l)}
                            </Col>
                          )}

                          <Col span={24}>
                            <FreebiePopup
                              viewer={this.props.viewer}
                              cart={this.props.viewer.cart}
                              relay={this.props.relay}
                              line={l}
                              selectedValue={l.product.id}
                              closable
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {lines.length - 1 === i ? <div style={{ display: 'flex', marginTop: '10px' }} /> : <Divider style={{ marginTop: '10px', marginBottom: '0px' }} />}
                  </div>
                );
              });

              const { header, borderStyle } = this.getHeaderAndBorder(showPriority, shippingMethod, store, k);

              return (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={kIndex}>
                  {header}
                  <div style={borderStyle}>
                    {items}
                  </div>
                </React.Fragment>
              );
            })}
          </Menu.Item>
        )}

        {this.props.showActionButtons && cart && lines.length > 0 &&(
        <Menu.Item key="subtotal" style={{height: 'auto', textAlign: "right"}}>
          <Divider style={{margin: '10px 0px'}} />
          <b>Subtotal:</b> <span className="price">${cart.subtotal.toFixed(2)}</span>
        </Menu.Item>
        )}

        {this.props.showActionButtons && (
        <Menu.Item key="action_btns" onClick={this.props.handleMenuClick} style={{textAlign: "center", marginBottom: '30px'}}>
          <Link style={{ display: 'inline-block', marginRight: '15px' }} to={getStoreId() ? '/pos' : '/checkout'} exact>
            <Button type="primary">
              Checkout
            </Button>
          </Link>

          {(lines.length > 0) && (
            <Button onClick={() => { this.handleEmptyCart(cart); }}>
              Empty Cart
            </Button>
          )}
        </Menu.Item>
        )}

      </Menu>
    );

    return menu;
  }
}
export default createFragmentContainer(ItemList, {
  viewer: graphql`
    fragment ItemList_viewer on Customer {
      ...FreebiePopup_viewer
      configs
      cart {
        ...CartFragment_cart @relay(mask: false)
        stores(first: 9999) {
          edges {
            node {
              id
              stocks {
                productId
                stock
              }
            }
          }
        }
      }
    }
  `,
});
