import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { get } from 'lodash';
import { Link } from 'found';
import { CheckCircleOutlined, MonitorOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { getMerchantId } from '../review/ReviewList';

class SuccessView extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      salesOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
  }

  componentDidMount() {
    const order = get(this.props.viewer.salesOrders, 'edges[0].node');
    if (typeof window.gapi === 'object' && order && this.props.viewer.email) {
      window.gapi.load('surveyoptin', () => {
        const now = new Date();
        // 7 days
        now.setDate(now.getDate() + 7);

        const date = now.toISOString().split('T')[0];

        const payload = {
          // REQUIRED FIELDS
          "merchant_id": getMerchantId(),
          "order_id": order.name,
          "email": this.props.viewer.email,
          "delivery_country": process.env.COUNTRY === 'NZ' ? 'NZ' : 'AU',
          "estimated_delivery_date": date,
        };
        window.gapi.surveyoptin.render(payload);
      });
    }
  }

  renderSuccess = (order) => (
    <div>
      <h1>Your order has been received</h1>
      <div style={{textAlign: 'center', fontWeight: 'bold'}}>
        <CheckCircleOutlined style={{fontSize: '95px', color: 'green'}} />
        <p style={{fontSize: '24px'}}>THANK YOU FOR YOUR PURCHASE</p>
        <p>
          Your order number is: <Link to={`/account/orders/${order.name}`}>#{order.name}</Link><br />
          We will email you an order confirmation with details.
        </p>

        <Row>
          <Col xs={24} sm={12} md={{push: 2, span: 12}} style={{marginBottom: '20px'}}>
            <Button type="primary" style={{width: '250px', fontWeight: 'bold'}}>
              <Link to="/">Continue Shopping</Link>
            </Button>
          </Col>

          <Col xs={24} sm={12} md={{pull: 2, span: 12}} style={{marginBottom: '20px'}}>
            <Button type="primary" style={{width: '250px', fontWeight: 'bold'}}>
              <Link to={`/account/orders/${order.name}`}>View Order Details</Link>
            </Button>
          </Col>
        </Row>

      </div>
    </div>
    )

  renderPaymentReview = (order) => (
    <div>
      <h1>Your order has been received and is currently under review</h1>
      <div style={{textAlign: 'center', fontWeight: 'bold'}}>
        <MonitorOutlined style={{fontSize: '95px', color: 'green'}} />
        <p style={{fontSize: '24px'}}>THANK YOU FOR YOUR PURCHASE</p>
        <p>
          Your order number is: <Link to={`/account/orders/${order.name}`}>#{order.name}</Link><br />
          Your order has been received and is currently under review.
        </p>

        <Row>
          <Col xs={24} sm={12} md={{push: 2, span: 12}} style={{marginBottom: '20px'}}>
            <Button type="primary" style={{width: '250px', fontWeight: 'bold'}}>
              <Link to="/">Continue Shopping</Link>
            </Button>
          </Col>

          <Col xs={24} sm={12} md={{pull: 2, span: 12}} style={{marginBottom: '20px'}}>
            <Button type="primary" style={{width: '250px', fontWeight: 'bold'}}>
              <Link to={`/account/orders/${order.name}`}>View Order Details</Link>
            </Button>
          </Col>
        </Row>

      </div>
    </div>
    )

  renderMessage = (order) => {
    switch (order.status) {
      case 1: return this.renderPaymentReview(order);
      default: return this.renderSuccess(order);
    }
  }

  render() {
    const { viewer } = this.props;
    const order = get(viewer.salesOrders, 'edges[0].node', {});

    return this.renderMessage(order);
  }
}
export default createFragmentContainer(SuccessView, {
  viewer: graphql`
    fragment SuccessView_viewer on Customer {
      id
      email
      salesOrders(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            status
          }
        }
      }
    }
  `,
});
